/**
 *
 * @Copyright 2023 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ReactNode } from 'react';
import { OrderOptions } from './general';
import { User } from './user';

export interface ContractTypePayload {
    name: string;
}

export interface ContractType {
    id: number;
    name: string;
    organizationId: number;
    createdDate: Date;
    lastModifiedDate: Date;
}

export enum AddSignerType {
    EXTERNAL = 'EXTERNAL',
    CURRENT_ORGANIZATION = 'CURRENT_ORGANIZATION',
}
export enum ContractTypeOptions {
    EDIT = 'EDIT',
    DELETE = 'DELETE'
}

export interface SignatureOption {
    value: SignatureType;
    label: string;
    icon: ReactNode;
    disabled: boolean;
    disableMessage: string;
    extraClasses?: string;
}

export interface Contract {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    userOwner: User;
    organizationId: number;
    contractType: ContractType;
    name: string;
    contractState: ContractState;
    signers: Signer[];
    externalId: string;
    downloadUrl: string;
    transactionId: number | null;
    contractStateNotes: string;
    voided: boolean;
}

export interface Signer {
    id: number;
    contractId: number;
    name: string;
    inviteEmail: string;
    phoneNumber: string;
    inviteCreatorId: string;
    inviteToken: string | null;
    expiresAt: string;
    signedAt: string | null;
    documentNumber: string;
    documentType: IdDocumentType;
    documentCountry: string;
    signatureType: SignatureType;
    editable?: boolean;
    language: string;
    signerUserId: string;

}

export enum ContractState {
    DRAFT = 'DRAFT',
    SIGNING = 'SIGNING',
    SIGNED = 'SIGNED',
    VOIDED = 'VOIDED',
}

export enum ContractStateNumeric {
    DRAFT = 33,
    SIGNING = 66,
    SIGNED = 100,
    VOIDED = 200,
}

export enum SignatureType {
    AES = 'AES',
    QESVIDEO = 'QESVIDEO',
    QESCMD = 'QESCMD'
}

export interface ContractPayload {
    id?: number;
    name: string;
    contractTypeId: number;
    externalId?: string;
    file: File | null;
    signers: Signer[] | null;
}

export enum ContractFieldsName {
    Name = 'name',
    Type = 'contractTypeId',
    Id = 'id',
    ExternalId = 'externalId',
    SignType = 'signatureType',
    File = 'file',
    Signers = 'signers'
}

export enum ContractConfigOption {
    General = 'general',
    SignatureOptions = 'signatureType',
    Signers = 'signers',
    SwapContract = 'swapContract',
    DeleteContract = 'deleteContract',
    CancelContract = 'cancelContract',
}

export enum SignerFieldsName {
    Name = 'name',
    Email = 'email',
    ContractId = 'contractId',
    PhoneNumber = 'phoneNumber',
    DocumentCountry = 'documentCountry',
    DocumentType = 'documentType',
    DocumentNumber = 'documentNumber',
    Signature = 'signatureType',
    Language = 'language',
}

export enum IdDocumentType {
    IDCARD = 'IDCARD',
    PASSPORT = 'PASSPORT',
    DRIVERLICENSE = 'DRIVERLICENSE',
    UNKNOWN = 'UNKNOWN'
}

export interface SignerPayload {
    name: string;
    email: string;
    phoneNumber: string;
    documentNumber: string;
    documentType: IdDocumentType;
    documentCountry: string;
    signatureType: SignatureType;
    language: string;
}

export interface SignerAsOrganizationMemberPayload {
    userId: string;
    phoneNumber: string;
    documentNumber: string;
    documentType: IdDocumentType;
    documentCountry: string;
    signatureType: SignatureType;
    language: string;
}

export interface SignerEmailPinPayload {
    authenticationPIN: string;
}

export enum FileUploadAction{
    UPLOAD='UPLOAD',
    REPLACE='REPLACE'
}

export interface PendingPlaceholder {
    signer: Signer;
    page: number;
    top: number;
    left: number;
    height: number;
    width: number;
}

export interface PlaceholderPayload {
    signerId: number;
    page: number;
    top: number;
    left: number;
    height: number;
    width: number;
}

export interface Placeholder {
    id: number;
    signer: Signer;
    createdDate: string;
    lastModifiedDate: string;
    contractId: number;
    page: number;
    top: number;
    left: number;
    height: number;
    width: number;
}

export interface AvailableSignatureType {
    countryName: string;
    flag: string;
    signature: [{
        signatureType: SignatureType;
        documents: {
            name: string;
            i18n: string;
            year?: number;
        }[];
    }];
    alpha3: string;
}

export interface SignatureCountry {
    name: string;
    flag: string;
    documents: { name: string; i18n: string; year?: number }[];
    alpha3: string;
}

export interface SignatureAvailabilityType {
    [key: string]: SignatureCountry[];
}

export interface ContractsFilterParams {
    _cursor?: string;
    _limit?: string;
    _sort?: OrderOptions;
    contractStatus?: ContractState[];
    contractTypeIds?: string[];
    externalId?: string;
    signerName?: string;
    pendingSignature?: true;
    ownerIds?: string[];
}
