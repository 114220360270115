/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { InviteStatus, Invite } from '../../types/members';
import { ReactComponent as Avatar } from '../../assets/images/avatar.svg';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { MembersContext, withMembersContext } from '../controllers/MembersContext';
import TextBadge from './TextBadge';
import { BadgeTextSize, TextBadgeColor } from '../../types/textBadge';
import { ShareComponent } from './ShareComponent';
import { memberInvitationUrl } from '../../services/members';
import { OrganizationsContext, withOrganizationsContext } from '../controllers/OrganizationsContext';
import { Tooltip } from './Tooltip';

interface OwnProps extends TranslationContext, MembersContext, OrganizationsContext {
    invite: Invite;
}

const MemberCollapse: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        organizationSelected,
        invite,
    } = props;

    const getBadgeVariantBasedOnStatus = (status: InviteStatus) => {
        switch (status) {
            case InviteStatus.PENDING:
                return TextBadgeColor.Yellow;
            case InviteStatus.ACCEPTED:
                return TextBadgeColor.Green;
            case InviteStatus.REJECTED:
                return TextBadgeColor.Red;
            default:
                return TextBadgeColor.Gray;
        }
    };
    return (
        <div className="invite-item card">
            <div className="invite-item__wrap">
                <div className="invite-item__wrap__profile">
                    <div className="invite-item__wrap__profile__avatar">
                        <Avatar />
                    </div>
                    <div className="invite-item__wrap__profile__info">
                        <TextBadge
                            color={getBadgeVariantBasedOnStatus(InviteStatus[invite.status])}
                            fontSize={BadgeTextSize.Small}
                            text={t(`invite.${InviteStatus[invite.status]}`)}
                        />
                        <Tooltip title={invite.email} placement="top-start">
                            <div className="invite-item__wrap__profile__info__email">
                                <p>{invite.email}</p>
                            </div>
                        </Tooltip>
                    </div>
                    
                </div>
                {invite.inviteToken && invite.status === InviteStatus.PENDING && (
                    <div className="invite-item__wrap__profile__actions">
                        <ShareComponent
                            shareTitle={t('invite.share.title')}
                            text={t('invite.share.text', { organization: organizationSelected?.organization })}
                            url={memberInvitationUrl(invite.inviteToken)}
                            subject={t('invite.share.emailSubject', { organization: organizationSelected?.organization })}
                            copyButtonText={t('invite.share.copyInvite')}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default withTranslationContext(withOrganizationsContext(withMembersContext(MemberCollapse)));
