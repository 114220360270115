/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 *
 */

import { Tab, Tabs } from '@mui/material';
import { isEqual } from 'lodash';
import {
    FC, useEffect, useRef, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as FiltersIcon } from '../../../assets/images/filters.svg';
import {
    PlanFeatureType, PurchaseItem, PurchaseItemListFilter, SettlementTypeEnum,
} from '../../../types/billing';
import { ButtonVariant, OrderOptions } from '../../../types/general';
import { formatToLocalDate } from '../../../utils/date';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { Button } from '../../elements/Button';
import { DatePicker } from '../../elements/DatePicker';
import { Drawer } from '../../elements/Drawer';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { ErrorResponse } from '../../../types/errors';
import { AppRoute } from '../../../constants/routes';
import { DropdownWithMultipleCheckbox } from '../../elements/DropdownWithMultipleCheckbox';

const featureTypesAvailable: PlanFeatureType[] = [
    PlanFeatureType.TRANSACTION,
    PlanFeatureType.ORG_MEMBERS,
    PlanFeatureType.CMD_SIGNATURE,
    PlanFeatureType.AES_SIGNATURE,
    PlanFeatureType.QES_SIGNATURE,
];

export const WalletMovementFilters: PurchaseItemListFilter = {
    _cursor: '',
    _limit: 25,
    _sort: OrderOptions.DESCENDING,
    featureTypes: [],
    maxDate: '',
    minDate: '',
    settlementType: undefined,
    userIds: [],
};

type OwnProps = TranslationContext & BillingContext & {
    settlementType?: SettlementTypeEnum;
    mode: 'personal'|'organization';
};

const BillingDebitMovementsScreenBase: FC<OwnProps> = (props) => {
    const {
        t,
        getPurchaseItemsHistory,
        organizationWallet,
        userWallet,
        settlementType,
        mode,
    } = props;

    const wallet = mode === 'organization' ? organizationWallet : userWallet;

    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [filters, setFilters] = useState({
        ...WalletMovementFilters,
        settlementType,
    });
    const [purchaseItems, setPurchaseItems] = useState<PurchaseItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [listLoadingError, setListLoadingError] = useState<ErrorResponse>();

    const prevFilters = useRef<PurchaseItemListFilter>();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const { _cursor, ...rest } = filters;
        const { _cursor: prevCursor, ...prevRest } = prevFilters.current || {};
        
        if (!isEqual(rest, prevRest)) {
            setPurchaseItems([]);
            loadBillingMovements(true);
        }

        prevFilters.current = filters;
    }, [wallet, filters]);

    const loadBillingMovements = async (reset?: boolean) => {
        if (!wallet) return;
        
        setIsLoading(true);
        setListLoadingError(undefined);

        const [data, error] = await getPurchaseItemsHistory(String(wallet?.id), { ...filters, _cursor: reset ? '' : filters._cursor });
        setIsLoading(false);

        if (error) {
            setListLoadingError(error);
            return;
        }

        setPurchaseItems((prev) => (!reset ? [...prev, ...data.results] : [...data.results]));
        setFilters((prev) => ({
            ...prev,
            _cursor: data.cursor,
        }));
    };

    const handleDateChange = (date: Date | null, field: string) => {
        if (!date || isNaN(date?.getTime())) {
            return;
        }
        setFilters((prev) => ({
            ...prev,
            [field]: date ? date.toISOString() : '',
        }));
    };

    const handleEndDateChange = (date: Date | null, field: string) => {
        if (!date || isNaN(date?.getTime())) {
            return;
        }
        date?.setHours(23, 59, 59, 999);
        handleDateChange(date, field);
    };

    const handleFeatureChange = (selected: string, checked: boolean) => {
        if (checked) {
            setFilters((f) => ({
                ...f,
                featureTypes: [...f.featureTypes ?? [], selected as PlanFeatureType],
            }));
        } else {
            setFilters((f) => ({
                ...f,
                featureTypes: f?.featureTypes?.filter((el) => el !== selected) ?? [],
            }));
        }
    };

    const clearFilters = () => {
        setIsOpenFilters(false);
        setFilters({
            ...WalletMovementFilters,
            settlementType,
        });
    };
    
    const onFilterSubmit = () => {
        setIsOpenFilters(false);
    };

    return (
        <section className="billing-debit-movements-screen">
            <div className="billing-debit-movements-screen__header">
                <div className="billing-debit-movements-screen__header__title">
                    <h1>{t('billingMovementScreen.title')}</h1>
                    <Button
                        extraClasses="circle-btn filters-trigger-btn"
                        onClick={() => setIsOpenFilters(true)}
                        testId="mobile-trigger-btn"
                    >
                        <FiltersIcon />
                    </Button>
                </div>
                <Tabs value={location.pathname} onChange={(_, val) => navigate(val)} data-testid="tab-list" role="navigation">
                    <Tab label={t('billingMovementScreen.tabs.credits')} value={AppRoute.OrganizationBillingCreditMovements} />
                    <Tab label={t('billingMovementScreen.tabs.debits')} value={AppRoute.OrganizationBillingDebitMovements} />
                    <Tab label={t('billingMovementScreen.tabs.subscription')} value={AppRoute.OrganizationBillingMovementsSubscription} />
                </Tabs>
            </div>
            <div className="billing-debit-movements-screen__content">
                <div className="billing-debit-movements-screen__content__filters">
                    <div className="billing-debit-movements-screen__content__filters__filter">
                        <DropdownWithMultipleCheckbox
                            name="featureTypes"
                            label={t('billingMovementScreen.filters.featureTypes')}
                            onChange={handleFeatureChange}
                            renderValue={((selected) => (selected as string[]).map((el) => t(`billing.featureType.${el}`)).join(', '))}
                            value={filters.featureTypes as string[]}
                            options={featureTypesAvailable.map((el) => ({
                                id: el,
                                label: t(`billing.featureType.${el}`),
                                value: Boolean(filters.featureTypes?.includes(el)),
                                disabled: false,
                                extraClasses: '',
                            }))}
                            placeholder={t('billingMovementScreen.placeholders.featureTypes')}
                            testId="feature-type-filter"
                        />
                        <DatePicker
                            name="minDate"
                            value={filters.minDate}
                            onChange={handleDateChange}
                            label={t('billingMovementScreen.filters.minDateLabel')}
                        />
                        <DatePicker
                            name="maxDate"
                            value={filters.maxDate}
                            onChange={handleDateChange}
                            label={t('billingMovementScreen.filters.maxDateLabel')}
                        />
                        <Button
                            onClick={clearFilters}
                            testId="clear-filters"
                        >
                            {t('billingMovementScreen.filters.cleanAll')}
                        </Button>
                    </div>
                </div>
                <div className="billing-debit-movements-screen__content__header">
                    <h3>{t('billingMovementScreen.labels.date')}</h3>
                    <h3>{t('billingMovementScreen.labels.user')}</h3>
                    <h3>{t('billingMovementScreen.labels.description')}</h3>
                    <h3>{t('billingMovementScreen.labels.status')}</h3>
                    <h3>{t('billingMovementScreen.labels.value')}</h3>
                </div>
                {isLoading && (<LoadingCircles size="m" variant="primary" />)}
                {!isLoading && purchaseItems.length === 0 && (
                    <p>{t('billingMovementScreen.noResultsFound')}</p>
                )}
                {!isLoading && listLoadingError && (
                    <p>{listLoadingError.errors.map((e) => e.getMessageTranslated(t))}</p>
                )}
                <InfiniteScrollWrapper
                    hasMore={!!filters._cursor}
                    requestMore={loadBillingMovements}
                    extraClasses="billing-debit-movements-screen__content__list"
                >
                    {purchaseItems.map((item) => (
                        <article className="billing-debit-movements-screen__content__list__item" key={item.id} data-testid={`purchase-item-${item.id}`}>
                            <div className="billing-debit-movements-screen__content__list__item__date">
                                <h4>{t('billingMovementScreen.labels.date')}</h4>
                                <p data-testid={`purchase-item-${item.id}-date`}>{formatToLocalDate(item.createdDate)}</p>
                            </div>
                            <div className="billing-debit-movements-screen__content__list__item__name">
                                <h4>{t('billingMovementScreen.labels.user')}</h4>
                                <p data-testid={`purchase-item-${item.id}-full-name`}>{item?.user?.fullName}</p>
                            </div>
                            <div className="billing-debit-movements-screen__content__list__item__description">
                                <h4>{t('billingMovementScreen.labels.description')}</h4>
                                <p data-testid={`purchase-item-${item.id}-feature-type`}>{t(`billing.featureType.${item.subscriptionItem.featureType}`, {
                                    purchaseItem: item,
                                })}
                                </p>
                            </div>
                            <div className="billing-debit-movements-screen__content__list__item__status" data-status={item.usageStatus}>
                                <h4>{t('billingMovementScreen.labels.status')}</h4>
                                <p data-testid={`purchase-item-${item.id}-usage-status`}>{t(`billing.purchaseItem.usageStatus.${item.usageStatus}`)}</p>
                            </div>
                            <div className="billing-debit-movements-screen__content__list__item__value" data-amount={item.unitsUsed} data-operation="debit">
                                <h4>{t('billingMovementScreen.labels.value')}</h4>
                                <p data-testid={`purchase-item-${item.id}-value`}>{t(`billingMovementScreen.value.${settlementType}`, {
                                    purchaseItem: item,
                                })}
                                </p>
                            </div>
                        </article>
                    ))}
                </InfiniteScrollWrapper>
            </div>
            <Drawer
                open={isOpenFilters}
                handleClose={() => setIsOpenFilters(false)}
                title={t('general.filter')}
                testId="filters-drawer"
            >
                <form className="filters-form" data-testid="filters-form">
                    <div className="filters-form__filters">
                        <DropdownWithMultipleCheckbox
                            name="featureTypes"
                            label={t('billingMovementScreen.filters.featureTypes')}
                            onChange={handleFeatureChange}
                            renderValue={((selected) => (selected as string[]).map((el) => t(`billing.featureType.${el}`)).join(', '))}
                            value={filters.featureTypes as string[]}
                            options={featureTypesAvailable.map((el) => ({
                                id: el,
                                label: t(`billing.featureType.${el}`),
                                value: Boolean(filters.featureTypes?.includes(el)),
                                disabled: false,
                                extraClasses: '',
                            }))}
                            placeholder={t('billingMovementScreen.placeholders.featureTypes')}
                            fullWidth
                            testId="feature-type-filter"
                        />
                        <h3>{t('billingMovementScreen.filters.dateRange')}</h3>
                        <DatePicker
                            name="minDate"
                            value={filters.minDate}
                            onChange={handleDateChange}
                            label={t('billingMovementScreen.filters.minDateLabel')}
                        />
                        <DatePicker
                            name="maxDate"
                            value={filters.maxDate}
                            onChange={handleEndDateChange}
                            label={t('billingMovementScreen.filters.maxDateLabel')}
                        />
                    </div>

                    <div className="filters-form__buttons">
                        <Button
                            variant={ButtonVariant.Curved}
                            extraClasses="secondary clean-btn"
                            onClick={clearFilters}
                            testId="clear-filters"
                        >
                            {t('billingMovementScreen.filters.cleanAll')}
                        </Button>
                        <Button
                            variant={ButtonVariant.Curved}
                            onClick={onFilterSubmit}
                            testId="submit-btn"
                        >
                            {t('general.applyFilters')}
                        </Button>
                    </div>
                </form>
                
            </Drawer>
        </section>
    );
};

export const BillingDebitMovementsScreen = withBillingContext(withTranslationContext(BillingDebitMovementsScreenBase));
