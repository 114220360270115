/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { useCornerstoneApi } from '../../api';
import { creditsValidations } from '../../constants/validations';
import {
    availablePlansUrl,
    createBasketUrl,
    latestSubscriptionUrl,
    organizationWalletUrl,
    purchaseOrderUrl,
    simulateOrderUrl,
    updateBasketUrl,
    usersWalletUrl,
    walletEventsUrl,
    walletPurchaseItems,
    walletUrl,
} from '../../services/billing';
import {
    clearCheckoutCart as clearCheckoutCartReducer,
    selectPlan,
    setBasket,
    setWallet,
    updateCredits,
} from '../../slicers/billingSlice';
import { setUserWallet } from '../../slicers/userSlice';
import {
    BillingEvent,
    OrderRequestPayload,
    PurchaseItem,
    SubscriptionPlan,
    Wallet,
    WalletUpdatePayload,
} from '../../types/billing';
import { ErrorResponse } from '../../types/errors';
import { useAppDispatch, useAppSelector } from '../../utils/storeHooks';
import { validateForm } from '../../utils/validations';
import { BillingContext, BillingContextProvider } from './BillingContext';
import { KeyedObject, ListResponse } from '../../types/general';

interface OwnProps {
    children: React.ReactNode;
}

const BillingController: FunctionComponent<OwnProps> = (props) => {
    const { children } = props;
    const CornerstoneAPI = useCornerstoneApi();

    const billingInfo = useAppSelector((state) => state.billing);
    const userWallet = useAppSelector((state) => state.user.userWallet);
    const organizationWallet = useAppSelector((state) => state.organization.organizationWallet);
    const dispatch = useAppDispatch();

    const selectPlanToPurchase: BillingContext['selectPlanToPurchase'] = (plan: SubscriptionPlan) => {
        dispatch(selectPlan(plan));
    };

    const changeCreditsToPurchase: BillingContext['changeCreditsToPurchase'] = (credits: number) => {
        if (credits < 0) return;

        dispatch(updateCredits(credits));
    };

    const clearCheckoutCart: BillingContext['clearCheckoutCart'] = () => {
        dispatch(clearCheckoutCartReducer());
        dispatch(updateCredits(0));
        dispatch(setWallet(null));
        dispatch(setBasket(null));
    };

    const getOrganizationWallet: BillingContext['getOrganizationWallet'] = async (organizationId) => {
        try {
            const { data } = await CornerstoneAPI.get<Wallet>(organizationWalletUrl(organizationId));
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };
    const getUsersWallet: BillingContext['getUsersWallet'] = async () => {
        try {
            const { data } = await CornerstoneAPI.get<Wallet>(usersWalletUrl());
            dispatch(setUserWallet(data));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Get Available Subscription Plans
     *
     */
    const getAvailablePlans: BillingContext['getAvailablePlans'] = async () => {
        try {
            const { data } = await CornerstoneAPI.get(availablePlansUrl());

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Update billing information
     *
     */
    const updateBillingInformation: BillingContext['updateBillingInformation'] = async (walletId: string, payload: WalletUpdatePayload) => {
        try {
            const { data } = await CornerstoneAPI.patch(walletUrl(walletId), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Get subscription details
     *
     */
    const getSubscriptionDetails: BillingContext['getSubscriptionDetails'] = async (walletId: string) => {
        try {
            const { data } = await CornerstoneAPI.get(latestSubscriptionUrl(walletId));
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const setWalletInfo: BillingContext['setWalletInfo'] = (wallet) => {
        dispatch(setWallet(wallet));
    };

    const createNewBasket: BillingContext['createNewBasket'] = async (walletId) => {
        try {
            const { data } = await CornerstoneAPI.post(createBasketUrl(walletId));
            dispatch(setBasket(data));
            return [data, null];
        } catch (e) {
            dispatch(setBasket(null));
            return [null, e as ErrorResponse];
        }
    };

    const updateBasket: BillingContext['updateBasket'] = async (walletId, updateBasketPayload) => {
        try {
            const { data } = await CornerstoneAPI.patch(updateBasketUrl(walletId), updateBasketPayload);
            dispatch(setBasket(data));
            return [data, null];
        } catch (e) {
            dispatch(setBasket(null));
            return [null, e as ErrorResponse];
        }
    };

    const simulateOrder: BillingContext['simulateOrder'] = async (payload: OrderRequestPayload) => {
        try {
            const { data } = await CornerstoneAPI.post(simulateOrderUrl(), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const purchaseOrder: BillingContext['purchaseOrder'] = async (payload: OrderRequestPayload) => {
        try {
            const { data } = await CornerstoneAPI.post(purchaseOrderUrl(), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };
    const validateCreditsInput: BillingContext['validateCreditsInput'] = (credits) => {
        const errors = validateForm({ credits }, creditsValidations);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    const getBillingEvents: BillingContext['getBillingEvents'] = async (walletId, filters) => {
        try {
            const { data } = await CornerstoneAPI.get<ListResponse<BillingEvent>>(walletEventsUrl(walletId, filters as KeyedObject<unknown>));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const getPurchaseItemsHistory: BillingContext['getPurchaseItemsHistory'] = async (walletId, filters) => {
        try {
            const params = {
                ...filters,
                _cursor: encodeURIComponent(filters._cursor ?? ''),
            };

            const { data } = await CornerstoneAPI.get<ListResponse<PurchaseItem>>(walletPurchaseItems(walletId, params));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    return (
        <BillingContextProvider
            value={{
                userWallet,
                organizationWallet,
                billingInfo,
                getAvailablePlans,
                selectPlanToPurchase,
                changeCreditsToPurchase,
                clearCheckoutCart,
                getOrganizationWallet,
                updateBillingInformation,
                getSubscriptionDetails,
                setWalletInfo,
                createNewBasket,
                updateBasket,
                simulateOrder,
                purchaseOrder,
                getUsersWallet,
                validateCreditsInput,
                getBillingEvents,
                getPurchaseItemsHistory,
            }}
        >
            {children}
        </BillingContextProvider>
    );
};

export { BillingController };
