/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 *
 */

import {
    Checkbox,
    ListItemText,
    MenuItem,
    Select,
    SelectProps,
} from '@mui/material';
import { FormValidationError, getErrorsForField } from '../../utils/validations';
import ErrorMessageBase from './ErrorMessage';
import { LoadingCircles } from './LoadingCircles';
import { OptionCheckboxGroupField } from '../../types/general';

type OwnProps = Pick<SelectProps, 'label'|'disabled'|'required'|'renderValue'|'placeholder'> & {
    name: string;
    onChange: (id: string, checked: boolean) => void;
    value: string[];
    prefix?: string;
    errors?: FormValidationError | null;
    options: OptionCheckboxGroupField[];
    disabledText?: string;
    isLoading?: boolean;
    fullWidth?: boolean;
    testId?: string;
}

/**
 * Unlockit custom selector.
 * To select this element during tests, target "select-<name>"
 *
 * @param props
 * @returns
 */
export const DropdownWithMultipleCheckbox = (props: OwnProps) => {
    const {
        options = [],
        name,
        onChange,
        prefix,
        errors,
        label,
        value,
        disabled,
        disabledText,
        placeholder,
        isLoading = false,
        required,
        fullWidth = false,
        renderValue,
        testId,
    } = props;

    const fieldErrors = getErrorsForField(name, errors);

    function isOptionChecked(option: OptionCheckboxGroupField) {
        return (value?.indexOf(String(option.id)) ?? 0) > -1;
    }

    return (
        <div className={`form-field ${fullWidth ? 'full-width' : ''}`} data-testid="form-field-root">
            <div className="form-field__top">
                {label && (
                    <label htmlFor={name} className={`form-field-label ${required && 'required'}`}>
                        {label}
                    </label>
                )}
            </div>
            <div className="form-field__container">
                {isLoading && (
                    <div className="input-loading-wrapper">
                        <LoadingCircles size="xs" variant="secondary" />
                    </div>
                )}
                {!isLoading && (
                    <div className={`form-field__container__input-wrapper ${fieldErrors ? 'has-error' : ''}`}>
                        {prefix && <span className="form-field__container__input-wrapper__prefix">{prefix}</span>}
                        <Select
                            multiple
                            value={value}
                            placeholder={placeholder}
                            renderValue={renderValue}
                            displayEmpty
                            multiline
                            sx={{
                                width: fullWidth ? '100%' : 250,
                                minWidth: 250,
                            }}
                            data-testid={testId}
                            disabled={disabled}
                        >
                            {options.map((o) => (
                                <MenuItem key={`dropdown-option-${o.id}`} onClick={() => onChange(String(o.id), !o.value)} data-testid={`dropdown-option-${o.id}`} disabled={o.disabled} selected={isOptionChecked(o)}>
                                    <Checkbox checked={isOptionChecked(o)} onChange={() => onChange(String(o.id), !o.value)} />
                                    <ListItemText primary={o.label} />
                                </MenuItem>
                            ))}
                        </Select>
                        {disabled && disabledText && (<span className="disable-warning">{disabledText}</span>)}
                        {fieldErrors ? <ErrorMessageBase errors={fieldErrors} field={name} /> : null}
                    </div>
                )}
            </div>
        </div>
    );
};
