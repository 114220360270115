/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ChangeEvent,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { FormControl, MenuItem } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import {
    Participant,
    ParticipantPermissions,
    ParticipantPermissionsPayload,
    WorkflowParticipantRoleEnum,
    WorkflowType,
} from '../../../types/workflows';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';

import { AppRoute } from '../../../constants/routes';
import Button from '../Button';
import { ButtonVariant, OptionSelectField } from '../../../types/general';
import { FormSelectField } from '../FormSelectField';
import { LoadingCircles } from '../LoadingCircles';
import Modal from '../Modal';
import { Permissions } from '../../../types/permissions';
import Select from '../../../__mocks__/@mui/material/Select';
import { ReactComponent as SwapIcon } from '../../../assets/images/swap.svg';
import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';
import { useAuthContext } from '../../controllers/AuthenticationContext';
import { Tooltip } from '../Tooltip';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';

interface OwnProps extends TranslationContext, WorkflowContext, OrganizationsContext {
    open: boolean;
    participant: Participant;
    workflowType: WorkflowType;
    canTransferOwner: boolean;
    canManagePermissions: boolean;
    onRemoveParticipant: () => void;
    onUpdateParticipantRole: (role: WorkflowParticipantRoleEnum) => void;
    onModalClose: () => void;
    onTransferOwnership: (newOwnerId: string) => void;
}

const ManageParticipantModalComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        organizationSelected,
        onModalClose,
        open,
        participant,
        workflowType,
        canTransferOwner,
        canManagePermissions,
        onRemoveParticipant,
        deleteParticipant,
        updateParticipantRole,
        onUpdateParticipantRole,
        transferOwnership,
        onTransferOwnership,
        getParticipantPermissions,
        updateParticipantPermissions,
        getParticipantRoleOptions,
    } = props;

    const { workflowId = '' } = useParams();
    const navigate = useNavigate();

    const { user } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);
    const [isUpdatingPermissions, setIsUpdatingPermissions] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [showDeleteMyselfConfirmModal, setShowDeleteMyselfConfirmModal] = useState(false);
    const [showTransferOwnershipConfirmModal, setShowTransferOwnershipConfirmModal] = useState(false);
    const [participantRole, setParticipantRole] = useState(participant.participantRole);
    const [participantPermissions, setParticipantPermissions] = useState<ParticipantPermissions | undefined>();
    const [rolesOptions, setRolesOptions] = useState<OptionSelectField<string>[]>([]);

    const isRoleSelectDisabled = !canManagePermissions
    || (participantRole === WorkflowParticipantRoleEnum.TRANSACTION_OWNER && user?.id === participant.userId);

    const transactionPermissionOptions = [
        { value: Permissions.VIEW_ORGANIZATION_TRANSACTIONS, label: t(`participantPermissions.${Permissions.VIEW_ORGANIZATION_TRANSACTIONS}`) },
        { value: Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, label: t(`participantPermissions.${Permissions.MANAGE_ORGANIZATION_TRANSACTIONS}`) },
    ];

    const documentsPermissionOptions = [
        { value: Permissions.UPLOAD_TRANSACTION_DOCUMENT, label: t(`participantPermissions.${Permissions.UPLOAD_TRANSACTION_DOCUMENT}`) },
        { value: Permissions.VIEW_TRANSACTION_DOCUMENT, label: t(`participantPermissions.${Permissions.VIEW_TRANSACTION_DOCUMENT}`) },
        { value: Permissions.MANAGE_TRANSACTION_DOCUMENT, label: t(`participantPermissions.${Permissions.MANAGE_TRANSACTION_DOCUMENT}`) },
        { value: Permissions.NONE, label: t(`participantPermissions.${Permissions.NONE}`) },
    ];

    const contractsPermissionOptions = [
        { value: Permissions.SIGN_TRANSACTION_CONTRACT, label: t(`participantPermissions.${Permissions.SIGN_TRANSACTION_CONTRACT}`) },
        { value: Permissions.VIEW_TRANSACTION_CONTRACT, label: t(`participantPermissions.${Permissions.VIEW_TRANSACTION_CONTRACT}`) },
        { value: Permissions.MANAGE_TRANSACTION_CONTRACT, label: t(`participantPermissions.${Permissions.MANAGE_TRANSACTION_CONTRACT}`) },
        { value: Permissions.NONE, label: t(`participantPermissions.${Permissions.NONE}`) },
    ];

    useEffect(() => {
        getRolesOptions();
        getPermissions();
    }, []);

    const getPermissions = async () => {
        const [participantPermissionsData] = await getParticipantPermissions(workflowId, participant.id.toString());
        if (participantPermissionsData) {
            setParticipantPermissions(participantPermissionsData);
        }
        setIsLoadingPermissions(false);
    };
    const handleRemoveParticipant = async () => {
        setIsLoading(true);

        const deleteParticipantError = await deleteParticipant(workflowId, String(participant.id));

        setIsLoading(false);

        if (deleteParticipantError) {
            setShowDeleteConfirmModal(false);
            toast.error(deleteParticipantError.errors[0].getMessageTranslated(t));
            return;
        }

        toast.success(t('workflows.participants.participantRemovedSuccessfully'));
        onRemoveParticipant();
        setShowDeleteConfirmModal(false);
    };

    const handleRemoveMyself = async () => {
        setIsLoading(true);

        const deleteParticipantError = await deleteParticipant(workflowId, String(participant.id));

        setIsLoading(false);

        if (deleteParticipantError) {
            setShowDeleteConfirmModal(false);
            toast.error(deleteParticipantError.errors[0].getMessageTranslated(t));
            return;
        }

        toast.success(t('workflows.participants.currentUserRemovedSuccessfully'));
        setShowDeleteMyselfConfirmModal(false);
        navigate(AppRoute.Workflows);
    };

    const getRolesOptions = async () => {
        const [rolesData] = await getParticipantRoleOptions(workflowType);
        if (rolesData) {
            const rolesTransformedOptions = rolesData
                .map((option) => ({
                    value: option.participantRole,
                    label: t(`participantRole.${option.participantRole}`),
                })).sort((a, b) => a.label.localeCompare(b.label));
            setRolesOptions(rolesTransformedOptions);
        }
    };

    const handleChangeRole = (_: string, value: string) => {
        const selectedRole = value as WorkflowParticipantRoleEnum;
        // don't allow to define another Role to Transaction Owner
        if (participantRole === WorkflowParticipantRoleEnum.TRANSACTION_OWNER) {
            return toast.error(t('errors.cannotEditOwnerRole'));
        }
        // don't allow to define Transaction Owner to another user
        if (selectedRole === WorkflowParticipantRoleEnum.TRANSACTION_OWNER) {
            return toast.error(t('errors.cannotSubmitOwnerRole'));
        }
        setIsLoading(true);

        updateParticipantRole(workflowId, String(participant.id), selectedRole).then((updateRoleError) => {
            if (updateRoleError) {
                toast.error(updateRoleError.errors[0].getMessageTranslated(t));
                return;
            }

            toast.success(t('workflows.participants.participantRoleSuccessfullyChanged'));
            setParticipantRole(selectedRole);
            onUpdateParticipantRole(selectedRole);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handleTransferOwnership = async () => {
        if (!participant.userId) {
            return;
        }

        setIsLoading(true);

        const transferOwnershipError = await transferOwnership(workflowId, participant.userId.toString());

        setIsLoading(false);

        if (transferOwnershipError) {
            toast.error(transferOwnershipError.errors[0].getMessageTranslated(t));
            return;
        }
        setShowTransferOwnershipConfirmModal(false);
        toast.success(t('workflows.participants.transferOwnershipSuccessfully'));
        onTransferOwnership(participant.userId);
    };

    const handlePermissionsChange = async (permissionLabelToUpdate: keyof ParticipantPermissionsPayload, permissionValueToUpdate: Permissions) => {
        if (!participantPermissions) return;

        setIsUpdatingPermissions(true);

        const previousPermissions = { ...participantPermissions };

        const updatedPermissionsPayload = {
            ...participantPermissions,
            [permissionLabelToUpdate]: [permissionValueToUpdate],
        } as ParticipantPermissions;
        setParticipantPermissions(updatedPermissionsPayload);

        const updatePermissionsError = await updateParticipantPermissions(workflowId, String(participant.id), {
            [permissionLabelToUpdate]: permissionValueToUpdate,
        });

        setIsUpdatingPermissions(false);

        if (updatePermissionsError) {
            toast.error(updatePermissionsError.errors[0].getMessageTranslated(t));
            setParticipantPermissions(previousPermissions);
        }
    };
    return (
        <Modal
            open={open}
            title={participant.name}
            handleClose={onModalClose}
            extraClasses="manage-participant-modal"
        >
            {isLoading ? (
                <LoadingCircles size="xs" variant="primary" />
            ) : (
                <>
                    {canManagePermissions && (
                        <>
                            <FormSelectField
                                name="role"
                                label={t('workflows.participants.labelRole')}
                                options={isRoleSelectDisabled ? [{ value: participantRole, label: t(`participantRole.${participantRole}`) }] : rolesOptions}
                                onChange={handleChangeRole}
                                value={participantRole}
                                disabled={isRoleSelectDisabled}
                            />
                            {isLoadingPermissions && <LoadingCircles size="xs" variant="primary" />}
                            {!isLoadingPermissions && participantPermissions && (
                                <div className="permissions form-field" data-testid="permissions-section">
                                    <div className="form-field__top">
                                        <label className="form-field-label">
                                            {t('participantPermissions.permissions')}
                                        </label>
                                    </div>
                                    <div className="permissions-card-list">
                                        <div key="permissions-card-transaction" className="permissions-card permissions-card-transaction">
                                            <div className="permissions-card__info">
                                                <p>{t('participantPermissions.transaction')}</p>
                                            </div>
                                            <FormControl
                                                variant="standard"
                                                disabled={isUpdatingPermissions}
                                            >
                                                <Select
                                                    value={participantPermissions?.transaction}
                                                    name="transaction"
                                                    onChange={(e: ChangeEvent<HTMLSelectElement>) => handlePermissionsChange('transaction', e.target.value as unknown as Permissions)}
                                                    data-testid="select-transaction"
                                                >
                                                    {Object.values(transactionPermissionOptions).map((option) => (
                                                        <MenuItem className="menu-item" key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div key="permissions-card-documents" className="permissions-card permissions-card-documents">
                                            <div className="permissions-card__info">
                                                <p>{t('participantPermissions.documents')}</p>
                                            </div>
                                            <FormControl
                                                variant="standard"
                                                disabled={isUpdatingPermissions}
                                            >
                                                <Select
                                                    value={participantPermissions?.documents}
                                                    name="documents"
                                                    onChange={(e: ChangeEvent<HTMLSelectElement>) => handlePermissionsChange('documents', e.target.value as unknown as Permissions)}
                                                    data-testid="select-documents"
                                                >
                                                    {Object.values(documentsPermissionOptions).map((option) => (
                                                        <MenuItem className="menu-item" key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div key="permissions-card-contracts" className="permissions-card permissions-card-contracts">
                                            <div className="permissions-card__info">
                                                <p>{t('participantPermissions.contracts')}</p>
                                            </div>
                                            <FormControl
                                                variant="standard"
                                                disabled={isUpdatingPermissions}
                                            >
                                                <Select
                                                    value={participantPermissions?.contracts}
                                                    name="contracts"
                                                    onChange={(e: ChangeEvent<HTMLSelectElement>) => handlePermissionsChange('contracts', e.target.value as unknown as Permissions)}
                                                    data-testid="select-contracts"
                                                >
                                                    {Object.values(contractsPermissionOptions).map((option) => (
                                                        <MenuItem className="menu-item" key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="permissions__bottom-message">
                                        <p>{t('participantPermissions.permissionMessage', { organizationName: organizationSelected?.organization.name })}</p>
                                        <Link to={AppRoute.OrganizationRoles}>{t('participantPermissions.seePermissions')}</Link>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    <div className="buttons workflow-participant-manage-buttons">
                        {canTransferOwner && user?.id !== participant.userId && (
                            <Tooltip title={!participant.userId ? t('workflows.participants.tooltipTransferOwnership') : ''} placement="bottom-start">
                                <div>
                                    <span
                                        className={classNames('inline-button', {
                                            'inline-button--disabled': !participant.userId,
                                        })}
                                        onClick={() => setShowTransferOwnershipConfirmModal(true)}
                                    >
                                        <Button
                                            extraClasses="primary"
                                            variant={ButtonVariant.IconBtn}
                                            disabled={!participant.userId}
                                        >
                                            <SwapIcon />
                                        </Button>
                                        <p>{t('workflows.participants.transferOwnership')}</p>
                                    </span>
                                </div>
                            </Tooltip>
                        )}
                        {user?.id === participant.userId && (
                            <Tooltip title={canTransferOwner && user?.id === participant.userId ? t('workflows.participants.tooltipDeleteParticipant') : ''} placement="bottom-start">
                                <div>
                                    <span
                                        className={classNames('inline-button', {
                                            'inline-button--disabled': canTransferOwner && user?.id === participant.userId,
                                        })}
                                        onClick={() => setShowDeleteMyselfConfirmModal(true)}
                                    >
                                        <Button
                                            extraClasses="danger"
                                            variant={ButtonVariant.IconBtn}
                                        >
                                            <TrashIcon />
                                        </Button>
                                        <p>{t('workflows.participants.removeMyself')}</p>
                                    </span>
                                </div>
                            </Tooltip>
                        )}
                        {canManagePermissions && (
                            <Tooltip title={canTransferOwner && user?.id === participant.userId ? t('workflows.participants.tooltipDeleteParticipant') : ''} placement="bottom-start">
                                <div>
                                    <span
                                        className={classNames('inline-button', {
                                            'inline-button--disabled': canTransferOwner && user?.id === participant.userId,
                                        })}
                                        onClick={() => setShowDeleteConfirmModal(true)}
                                    >
                                        <Button
                                            extraClasses="danger"
                                            variant={ButtonVariant.IconBtn}
                                        >
                                            <TrashIcon />
                                        </Button>
                                        <p>{t('workflows.participants.removeParticipant')}</p>
                                    </span>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </>
            )}
            <Modal
                open={showDeleteConfirmModal}
                handleClose={() => setShowDeleteConfirmModal(false)}
                title={t('workflows.participants.removeParticipant')}
                handleConfirm={handleRemoveParticipant}
            >
                <p>{t('workflows.participants.confirmDeleteParticipant')}</p>
            </Modal>
            <Modal
                open={showTransferOwnershipConfirmModal}
                handleClose={() => setShowTransferOwnershipConfirmModal(false)}
                title={t('workflows.participants.transferOwnership')}
                handleConfirm={handleTransferOwnership}
            >
                <p>{t('workflows.participants.confirmTransferOwnership')}</p>
            </Modal>
            <Modal
                open={showDeleteMyselfConfirmModal}
                handleClose={() => setShowDeleteMyselfConfirmModal(false)}
                title={t('workflows.participants.removeMyself')}
                handleConfirm={handleRemoveMyself}
            >
                <p>{t('workflows.participants.confirmDeleteMyself')}</p>
            </Modal>
        </Modal>
    );
};

export const ManageParticipantModal = withOrganizationsContext(withWorkflowContext(withTranslationContext(ManageParticipantModalComponent)));
