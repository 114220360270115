/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ListResponse } from '../types/general';
import { OrganizationAccess } from '../types/organizations';
import { PermissionList } from '../types/permissions';
import { SlicerName } from './types';
import { Wallet } from '../types/billing';
import { Role } from '../types/roles';

export interface OrganizationState {
    organizationSelected: OrganizationAccess | null;
    currentPermissions: PermissionList | null;
    organizationWallet: Wallet | null;
    organizationAccesses: ListResponse<OrganizationAccess>;
    organizationAccessRoles: Role[];
}

const organizationInitialState: OrganizationState = {
    organizationSelected: null,
    currentPermissions: null,
    organizationWallet: null,
    organizationAccesses: { cursor: '', results: [] },
    organizationAccessRoles: [],
};

export const organizationSlice = createSlice({
    name: SlicerName.Organization,
    initialState: organizationInitialState,
    reducers: {
        setOrganizationSelected: (state, action: PayloadAction<OrganizationState['organizationSelected']>) => {
            state.organizationSelected = action.payload;
        },
        setCurrentPermissions: (state, action: PayloadAction<OrganizationState['currentPermissions']>) => {
            state.currentPermissions = action.payload;
        },
        setOrganizationWallet: (state, action: PayloadAction<OrganizationState['organizationWallet']>) => {
            state.organizationWallet = action.payload;
        },
        setOrganizationAccesses: (state, action: PayloadAction<OrganizationState['organizationAccesses']>) => {
            state.organizationAccesses = action.payload;
        },
        setOrganizationAccessRoles: (state, action: PayloadAction<OrganizationState['organizationAccessRoles']>) => {
            state.organizationAccessRoles = action.payload;
        },
        loadMoreOrganizationAccesses: (state, action: PayloadAction<OrganizationState['organizationAccesses']>) => {
            action.payload.results.forEach((r) => state.organizationAccesses.results.push(r));
            state.organizationAccesses.cursor = action.payload.cursor;
        },
    },
});

export const {
    setOrganizationSelected, setCurrentPermissions, setOrganizationAccessRoles, setOrganizationWallet, setOrganizationAccesses, loadMoreOrganizationAccesses,
} = organizationSlice.actions;

export const organizationReducer = organizationSlice.reducer;
