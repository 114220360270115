/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ComponentType,
    FC,
    createContext,
    useContext,
} from 'react';
import {
    Contract, ContractState, IdDocumentType, SignatureType, Signer, SignerEmailPinPayload,
} from '../../types/contracts';
import { DataOrError, ErrorResponse } from '../../types/errors';

import { SignerInvitationController } from './SignerInvitationController';
import { DEFAULT_LANGUAGE } from '../../types/preferences';

export interface SignerInvitationContext {
    loadContractInformation(signerToken: string): Promise<DataOrError<Contract, ErrorResponse>>;
    tryAssigningUserToSigner: (signerToken: string) => Promise<DataOrError<Signer, ErrorResponse>>;
    validatePIN: (signerToken: string, payload: SignerEmailPinPayload) => Promise<DataOrError<Contract, ErrorResponse>>;
}
const contractDefault: Contract = {
    id: 0,
    name: '',
    organizationId: 0,
    userOwner: {
        id: '0',
        username: '',
        firstName: '',
        lastName: '',
        fullName: '',
        contactEmail: '',
    },
    signers: [],
    contractType: {
        id: 0,
        name: 'ContractType',
        organizationId: 90000,
        createdDate: new Date(),
        lastModifiedDate: new Date(),
    },
    contractState: ContractState.DRAFT,
    createdDate: '',
    lastModifiedDate: '',
    externalId: '',
    downloadUrl: '',
    transactionId: 1,
    contractStateNotes: '',
    voided: false,
};

const signerDefault = {
    id: 1,
    contractId: 123,
    name: 'Test Signer',
    inviteEmail: 'test@mail.com',
    phoneNumber: '+351912912912',
    inviteCreatorId: '',
    inviteToken: 'token',
    expiresAt: '',
    signedAt: '',
    documentNumber: '',
    documentType: IdDocumentType.IDCARD,
    documentCountry: 'PRT',
    signatureType: SignatureType.QESCMD,
    language: DEFAULT_LANGUAGE,
    signerUserId: '',
};

export const signerInvitationContextDefaultValue: SignerInvitationContext = {
    loadContractInformation: async () => [contractDefault, null],
    tryAssigningUserToSigner: async () => [signerDefault, null],
    validatePIN: async () => [contractDefault, null],
};

const signerInvitationContextInstance = createContext<SignerInvitationContext>(signerInvitationContextDefaultValue);

export const useContextSignerInvitation = (): SignerInvitationContext => {
    return useContext(signerInvitationContextInstance);
};

export const SignerInvitationContextProvider = signerInvitationContextInstance.Provider;
export const SignerInvitationContextConsumer = signerInvitationContextInstance.Consumer;

export const withSignerInvitationContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof SignerInvitationContext>> => (props) => (
    <SignerInvitationController>
        <SignerInvitationContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </SignerInvitationContextConsumer>
    </SignerInvitationController>
);
