/*
 *
 * @Copyright 2023 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export enum TextBadgeType {
    Filled = 'filled',
    Outlined = 'outlined'
}

export enum BadgeTextSize {
    Medium = '14px',
    Small = '12px'
}

export enum TextBadgeColor {
    Purple = 'purple',
    Gray = 'gray',
    Green ='green',
    Yellow ='yellow',
    Red = 'red',
}
