/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { toast } from 'react-toastify';
import { ContractType, ContractTypePayload } from '../../../types/contracts';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';
import ContractTypeListItem from '../../elements/contracts/ContractTypeListItem';
import { FormField } from '../../elements/FormField';
import { FormValidationError } from '../../../utils/validations';
import Modal from '../../elements/Modal';
import { withOrganizationsContext } from '../../controllers/OrganizationsContext';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { preparePageTitle } from '../../../utils/route';
import { Permissions } from '../../../types/permissions';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';

interface OwnProps extends TranslationContext, ContractsContext { }

enum ContractTypeFieldsName {
    Name = 'name',
}

export interface ContractTypeFields {
    [ContractTypeFieldsName.Name]: string;
}

const initialFieldsState: ContractTypeFields = {
    [ContractTypeFieldsName.Name]: '',
};

const ContractsConfigurationScreenComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        submitNewContractType,
        validateContractTypeForm,
        getContractTypes,
    } = props;
    const [contractTypes, setContractTypes] = useState<ContractType[]>([]);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [contractTypeFields, setContractTypeFields] = useState<ContractTypePayload>(initialFieldsState);
    const [errorsForm, setErrorsForm] = useState<FormValidationError | null>(null);
    const [cursor, setCursor] = useState('');

    const canManage = useUserHasPermission([Permissions.MANAGE_CONTRACT_TYPE]);

    useEffect(() => {
        document.title = preparePageTitle(t('contractTypeConfigurator.title'));
        getContractTypesList();
    }, []);

    /* GET */
    const getContractTypesList = async () => {
        const contractData = await getContractTypes(cursor);
        setContractTypes([...contractTypes, ...contractData.results]);
        setCursor(contractData.cursor);
    };

    /* CREATE */
    const onCreateSuccess = (newContractType: ContractType) => {
        setContractTypes([...contractTypes, newContractType]);
        toast.success(t('contractTypeConfigurator.createSuccess'));
        onFieldChange(ContractTypeFieldsName.Name, '');
        handleCloseModalCreate();
    };

    const onCreateFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    const handleOpenModalCreate = () => {
        setOpenModalConfirm(true);
    };

    const handleCloseModalCreate = () => {
        setOpenModalConfirm(false);
        onFieldChange(ContractTypeFieldsName.Name, '');
    };

    const onCreateFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const fieldsTransformed: ContractTypePayload = {
            [ContractTypeFieldsName.Name]: contractTypeFields[ContractTypeFieldsName.Name].trim(),
        };
        const errors = validateContractTypeForm({ ...fieldsTransformed });
        setErrorsForm(errors);
        if (errors) return;

        const [contractTypeData, contractTypeError] = await submitNewContractType(fieldsTransformed);
        if (contractTypeData) {
            return onCreateSuccess(contractTypeData);
        }

        return onCreateFailure(contractTypeError.errors[0].getMessageTranslated(t));
    };

    const onFieldChange = (name: string, value: string) => {
        setContractTypeFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    /* EDIT */
    const updateListOnEditSuccess = (idToUpdate: number, newName: string) => {
        const updatedContractTypes = contractTypes.map((contract) => {
            if (contract.id === idToUpdate) {
                return {
                    ...contract,
                    name: newName,
                };
            }
            return contract;
        });
        setContractTypes(updatedContractTypes);
    };

    /* DELETE */
    const updateListOnDeleteSuccess = (idToDelete: number) => {
        const updatedContractTypes = contractTypes.filter((contract) => contract.id !== idToDelete);
        setContractTypes(updatedContractTypes);
    };

    return (
        <div className="contracts-configurator-screen">
            <div className="contracts-configurator-screen__header">
                <h1>{t('contractTypeConfigurator.title')}</h1>
                {canManage && (
                    <Button
                        variant={ButtonVariant.Curved}
                        extraClasses="large-add-btn shorter-btn"
                        onClick={handleOpenModalCreate}
                        testId="large-add-btn"
                    >
                        {t('contractTypeConfigurator.createBtn')}
                    </Button>
                )}
            </div>
            <div className="contracts-configurator-screen__list">
                <InfiniteScrollWrapper
                    hasMore={!!cursor}
                    requestMore={getContractTypesList}
                >
                    {contractTypes.map((contractType) => (
                        <ContractTypeListItem
                            key={contractType.id}
                            contractType={contractType}
                            updateListOnDeleteSuccess={updateListOnDeleteSuccess}
                            updateListOnEditSuccess={updateListOnEditSuccess}
                        />
                    ))}
                </InfiniteScrollWrapper>
            </div>
            {canManage && (
                <div className="add-btn-wrap" onClick={handleOpenModalCreate} data-testid="add-btn">
                    <Fab size="medium" color="primary" aria-label="add">
                        <AddIcon />
                    </Fab>
                </div>
            )}
            <Modal
                open={openModalConfirm}
                title={t('contractTypeConfigurator.createTitle')}
                handleClose={handleCloseModalCreate}
            >
                <form
                    className="form"
                    onSubmit={onCreateFormSubmit}
                >
                    <div className="form__fields">
                        <FormField
                            name={ContractTypeFieldsName.Name}
                            value={contractTypeFields[ContractTypeFieldsName.Name]}
                            onChange={onFieldChange}
                            maxLength={250}
                            placeholder={t('contractTypeConfigurator.contractTypeName')}
                            errors={errorsForm}
                            fullWidth
                        />
                    </div>
                    <div className="form__bottom">
                        <Button
                            isSubmit
                            variant={ButtonVariant.Curved}
                        >
                            {t('general.create')}
                        </Button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export const ContractsConfigurationScreen = withTranslationContext(withOrganizationsContext(withContractsContext(ContractsConfigurationScreenComponent)));
