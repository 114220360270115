/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export const userByEmailUrl = (email: string) => {
    return `/users/email/?address=${email}`;
};
