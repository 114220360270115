/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { TranslationContext, withTranslationContext } from './TranslationContext';

import { useCornerstoneApi } from '../../api';
import { UserContext, UserContextProvider } from './UserContext';
import {
    userByEmailUrl,
} from '../../services/users';
import { ErrorResponse } from '../../types/errors';
 
interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps & TranslationContext;

const UserController: FunctionComponent<Props> = (props: Props) => {
    const { children } = props;
    const CornerstoneApiInstance = useCornerstoneApi();

    const getUserByEmail: UserContext['getUserByEmail'] = async (email: string) => {
        try {
            const response = await CornerstoneApiInstance.get(userByEmailUrl(email));
            
            return [response, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    return (
        <UserContextProvider
            value={{
                getUserByEmail,
            }}
        >
            {children}
        </UserContextProvider>
    );
};

export default withTranslationContext(UserController);
