/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    Avatar,
    AvatarGroup,
    Tooltip,
} from '@mui/material';
import classNames from 'classnames';
import { FunctionComponent, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronDown } from '../../../assets/images/arrow-down-broken.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/checkmark-green.svg';
import { ReactComponent as ChevronUp } from '../../../assets/images/chevron-up.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/clock.svg';
import { ReactComponent as CrossIcon } from '../../../assets/images/cross-red.svg';
import { ReactComponent as DraftIcon } from '../../../assets/images/document-dash.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/images/document-icon.svg';
import { ReactComponent as PenIcon } from '../../../assets/images/pen.svg';
import { ReactComponent as OwnerIcon } from '../../../assets/images/profile.svg';
import { AppRoute } from '../../../constants/routes';
import { Contract, ContractState } from '../../../types/contracts';
import { getInitialsFromFirstAndLastName } from '../../../utils/misc';
import { buildUrl } from '../../../utils/navigation';
import { useAuthContext } from '../../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

interface OwnProps extends TranslationContext {
    contract: Contract;
}

const ContractCard: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        contract,
    } = props;

    const { user } = useAuthContext();
    const [isExpanded, setIsExpanded] = useState(false);

    const signingMode = contract.contractState === ContractState.SIGNING;

    const numberOfSignatures = useMemo(() => {
        const signers = contract.signers ?? [];

        let signed = 0;
        signers.forEach((signer) => {
            if (signer.signedAt) {
                signed++;
            }
        });

        return signed;
    }, [contract.signers]);

    const signerIsCurrentUser = () => {
        return contract.signers?.some((signer) => signer.inviteEmail === user?.contactEmail && !signer.signedAt);
    };

    const getOpenContractUrl = () => {
        switch (contract.contractState) {
            case ContractState.DRAFT:
                return buildUrl(AppRoute.DraftContract, { contractId: String(contract.id) });
            case ContractState.SIGNING:
            case ContractState.SIGNED:
            case ContractState.VOIDED:
            default:
                return buildUrl(AppRoute.ShowContract, { contractId: String(contract.id) });
        }
    };

    const renderContractStatusIcon = () => {
        switch (contract.contractState) {
            case ContractState.DRAFT:
                return <DraftIcon />;
            case ContractState.SIGNING:
                return <ClockIcon />;
            case ContractState.SIGNED:
                return <CheckIcon />;
            case ContractState.VOIDED:
            default:
                return <CrossIcon />;
        }
    };

    return (
        <div className="contract-card card" data-testid="card">
            <Link to={getOpenContractUrl()} key={`link-${contract.id}`}>
                <div className="contract-card__header">
                    <div className="contract-card__header__info">
                        <Avatar alt="owner avatar" sizes="14">
                            <OwnerIcon />
                        </Avatar>
                        <h2 className="contract-card__header__info__contract-owner">
                            {contract.userOwner.fullName}
                        </h2>
                    </div>
                    {contract.externalId && (
                        <div className="contract-card__header__contract-id">
                            {contract.externalId}
                        </div>
                    )}
                </div>
                <h2 className="contract-card__contract-type">{contract.contractType.name}</h2>
                <div className="contract-card__contract-name">
                    <DocumentIcon />
                    <h3>
                        {contract.name}
                    </h3>
                </div>
            </Link>
            <div className="contract-card__signatures-wrapper">
                <div className="contract-card__signatures-wrapper__general-info">
                    <div className={`contract-card__signatures-wrapper__general-info__current-signer-state contract-card__signatures-wrapper__general-info__current-signer-state--${signerIsCurrentUser() && signingMode ? 'requires-your-signature' : contract.contractState}`}>
                        {renderContractStatusIcon()}
                        {signerIsCurrentUser() && signingMode
                            ? t('contracts.requiresYourSignature')
                            : t(`contractStates.${contract.contractState}`)}
                    </div>
                    <div className="contract-card__signatures-wrapper__general-info__signing-state">
                        <div className="contract-card__signatures-wrapper__general-info__signing-state__signers">
                            <AvatarGroup max={3} spacing={0}>
                                {contract.signers?.map((signer) => (
                                    <div
                                        key={`${contract.id}-${signer.id}`}
                                        className="contract-card__signatures-wrapper__general-info__signing-state__signers__signer"
                                    >
                                        <Tooltip title={signer.name} placement="top">
                                            <Avatar>
                                                {getInitialsFromFirstAndLastName(signer.name)}
                                            </Avatar>
                                        </Tooltip>
                                        {signer.signedAt ? (
                                            <CheckIcon className="contract-card__signatures-wrapper__general-info__signing-state__signers__signer__signed" />
                                        ) : (
                                            <ClockIcon className="contract-card__signatures-wrapper__general-info__signing-state__signers__signer__pending" />
                                        )}
                                    </div>
                                ))}
                            </AvatarGroup>
                        </div>
                        {contract.signers?.length > 0 && (
                            <div>
                                <div className={classNames('contract-card__signatures-wrapper__general-info__signing-state__contract-state',
                                    { 'contract-card__signatures-wrapper__general-info__signing-state__contract-state--all-signed': contract.signers.length === numberOfSignatures })}
                                >
                                    <PenIcon />{numberOfSignatures}/{contract.signers?.length ?? 0}
                                </div>
                                <button type="button" className="contract-card__signatures-wrapper__general-info__signing-state__expand-signers" onClick={() => setIsExpanded((prev) => !prev)}>
                                    {isExpanded ? (
                                        <ChevronUp />
                                    ) : (
                                        <ChevronDown />
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {isExpanded && contract.signers.length > 0 && (
                    <div className="contract-card__signatures-wrapper__signers-list" data-testid="signers-list">
                        {contract.signers.map((signer) => (
                            <div key={signer.id} className="contract-card__signatures-wrapper__signers-list__signer">
                                <div className="contract-card__signatures-wrapper__signers-list__signer__avatar">
                                    <Avatar>{getInitialsFromFirstAndLastName(signer.name)}</Avatar>
                                    {signer.signedAt ? <CheckIcon className="contract-card__signatures-wrapper__signers-list__signer__avatar__signed" /> : <ClockIcon className="contract-card__signatures-wrapper__signers-list__signer__avatar__pending" />}
                                </div>
                                <div>
                                    <div className="contract-card__signatures-wrapper__signers-list__signer__name">
                                        {signer.name}
                                    </div>
                                    <div className="contract-card__signatures-wrapper__signers-list__signer__signature">
                                        {`${t('contracts.signersSignature')} ${t(`signatureOptions.${signer.signatureType}`)}`}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default withTranslationContext(ContractCard);
