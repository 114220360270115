/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactNode } from 'react';
import { Alert as AlertMUI } from '@mui/material';
import { ReactComponent as AlertIcon } from '../../assets/images/alert.svg';

interface OwnProps {
    variant?: 'success' | 'info' | 'warning' | 'error';
    message: string;
    icon?: ReactNode;
    button?: ReactNode;
}

const Alert: FunctionComponent<OwnProps> = (props) => {
    const {
        variant = 'warning',
        message,
        icon,
        button,
    } = props;

    return (
        <AlertMUI
            className="alert"
            severity="success"
            color={variant}
            icon={icon ?? <AlertIcon />}
            action={button}
            data-testid="alert"
        >
            {message}
        </AlertMUI>
    );
};

export { Alert };
