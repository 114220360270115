/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, useEffect, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { PlanFeatureType } from '../../../types/billing';
import { ButtonVariant, ListResponse } from '../../../types/general';
import {
    Invite,
    InviteParams,
    Member,
    MemberInviteFilters,
} from '../../../types/members';
import { Role } from '../../../types/roles';
import { preparePageTitle } from '../../../utils/route';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { MembersContext, withMembersContext } from '../../controllers/MembersContext';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { RolesContext, withRolesContext } from '../../controllers/RolesContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { useBillingFeature } from '../../elements/billing/UseBillingFeature';
import Button from '../../elements/Button';
import { FloatingAddAction } from '../../elements/FloatingAddAction';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import InviteItem from '../../elements/InviteItem';
import { LoadingCircles } from '../../elements/LoadingCircles';
import Modal from '../../elements/Modal';
import { MemberCollapse } from '../../elements/organizations/MemberCollapse';
import { MemberInvitationForm } from '../../elements/organizations/MemberInvitationForm';

interface OwnProps extends OrganizationsContext, MembersContext, RolesContext, TranslationContext, BillingContext { }

const MembersScreenComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        organizationWallet,
        getSubscriptionDetails,
        organizationSelected,
        getRoles,
        getMembers,
        getInvites,
        loadMemberRoles,
        t,
    } = props;
    const [searchParams] = useSearchParams();
    const inviteParam = searchParams.get('invite') ?? '';

    const [invites, setInvites] = useState<Invite[]>([]);
    const [filterSelected, setFilterSelected] = useState<MemberInviteFilters>(MemberInviteFilters.ALL);
    const [members, setMembers] = useState<Member[]>([]);
    const [roles, setRoles] = useState<ListResponse<Role>>({
        cursor: '',
        results: [],
    });
    const [openInviteModal, setOpenInviteModal] = useState(!!inviteParam);
    const [cursor, setCursor] = useState('');
    const [isRequesting, setIsRequesting] = useState(false);

    const {
        consumeFeature,
        billingModal,
        isLoading: loadingBilling,
    } = useBillingFeature({
        t,
        getSubscriptionDetails,
        organizationWallet,
    });

    useEffect(() => {
        setInvites([]);
        setMembers([]);
        setIsRequesting(true);
        if (filterSelected === MemberInviteFilters.ALL) {
            getMembersList('');
            getRolesList();
        } else {
            getInvitesList('');
        }
        setCursor('');
        setIsRequesting(false);
    }, [filterSelected]);

    useEffect(() => {
        document.title = preparePageTitle(t('membersScreen.title'));
    }, []);

    const getInvitesList = async (argCursor?: string) => {
        const params: InviteParams = {
            _status: filterSelected,
            _cursor: argCursor ?? cursor,
        };

        const invitesData = await getInvites(Number(organizationSelected?.organization?.id), params);

        if (!invitesData) return;
        
        setInvites((prev) => [...prev, ...invitesData.results]);
        setCursor(invitesData.cursor);
    };

    const getMembersList = async (argCursor?: string) => {
        const membersData = await getMembers(Number(organizationSelected?.organization?.id), argCursor ?? cursor);

        if (!membersData) return;
        
        setMembers((prev) => [...prev, ...membersData.results]);
        setCursor(membersData.cursor);
    };

    const getRolesList = async () => {
        const rolesData = await getRoles(Number(organizationSelected?.organization?.id));

        if (!rolesData) return;

        setRoles(rolesData);
    };

    const orgMemberFeatureInvitationWrapper = () => {
        consumeFeature(PlanFeatureType.ORG_MEMBERS, handleOpenInvitationModal);
    };

    const handleOpenInvitationModal = () => {
        setOpenInviteModal(true);
    };

    const handleCloseInvitationModal = () => {
        setOpenInviteModal(false);
    };

    const onRoleAssigned = (member: Member, role: Role) => {
        if (members) {
            const currMembers = members?.map((el) => {
                if (el.id === member.id) {
                    el.roles.push(role);
                }
                return el;
            });

            setMembers(currMembers);
        }
    };

    const onRoleUnassigned = (member: Member, role: Role) => {
        if (members) {
            const currMembers = members?.map((el) => {
                const currElement = el;
                if (currElement.id === member.id) {
                    currElement.roles = el.roles.filter((r) => r.id !== role.id);
                }
                return el;
            });

            setMembers(currMembers);
        }
    };

    return (
        <div className="members-screen">
            <div className="members-screen__header">
                <h1>{t('membersScreen.title')}</h1>
                {loadingBilling && (
                    <LoadingCircles size="xs" variant="neutral" />
                )}
                {!loadingBilling && (
                    <Button
                        variant={ButtonVariant.Curved}
                        extraClasses="large-add-btn shorter-btn"
                        testId="btn-add-member-default"
                        onClick={orgMemberFeatureInvitationWrapper}
                    >
                        {t('membersScreen.addNewMember')}
                    </Button>
                )}
                {billingModal}
            </div>
            <div className="members-screen__wrap">
                <div className="members-screen__wrap__filters">
                    <button
                        type="button"
                        className={filterSelected === MemberInviteFilters.ALL ? 'selected' : ''}
                        onClick={() => { setFilterSelected(MemberInviteFilters.ALL); }}
                    >
                        {t(`membersScreen.${MemberInviteFilters.ALL}`)}
                    </button>
                    <button
                        type="button"
                        className={filterSelected === MemberInviteFilters.PENDING ? 'selected' : ''}
                        onClick={() => { setFilterSelected(MemberInviteFilters.PENDING); }}
                    >
                        {t(`membersScreen.${MemberInviteFilters.PENDING}`)}
                    </button>
                    <button
                        type="button"
                        className={filterSelected === MemberInviteFilters.ACCEPTED ? 'selected' : ''}
                        onClick={() => { setFilterSelected(MemberInviteFilters.ACCEPTED); }}
                    >
                        {t(`membersScreen.${MemberInviteFilters.ACCEPTED}`)}
                    </button>
                    <button
                        type="button"
                        className={filterSelected === MemberInviteFilters.REJECTED ? 'selected' : ''}
                        onClick={() => { setFilterSelected(MemberInviteFilters.REJECTED); }}
                    >
                        {t(`membersScreen.${MemberInviteFilters.REJECTED}`)}
                    </button>
                </div>
                {isRequesting && <LoadingCircles size="xs" variant="primary" />}
                {!isRequesting && invites && filterSelected !== MemberInviteFilters.ALL && (
                    <InfiniteScrollWrapper
                        hasMore={!!cursor}
                        requestMore={getInvitesList}
                    >
                        {invites?.map((invite) => (
                            <InviteItem key={invite.id} invite={invite} />
                        ))}
                        {invites.length === 0 && (<p>{t('membersScreen.noPendingInvites')}</p>)}
                    </InfiniteScrollWrapper>
                )}
                {!isRequesting && members && filterSelected === MemberInviteFilters.ALL && (
                    <InfiniteScrollWrapper
                        hasMore={!!cursor}
                        requestMore={getMembersList}
                    >
                        {members?.map((member) => (
                            <MemberCollapse
                                onRoleAssigned={onRoleAssigned}
                                onRoleUnassigned={onRoleUnassigned}
                                key={member.id}
                                member={member}
                                roles={roles}
                                {...loadMemberRoles}
                            />
                        ))}
                        {members.length === 0 && (<p>{t('membersScreen.noMembers')}</p>)}
                    </InfiniteScrollWrapper>
                )}
            </div>
            <FloatingAddAction type="button" onClick={orgMemberFeatureInvitationWrapper} testId="btn-add-member-mobile" />
            <Modal
                open={openInviteModal}
                handleClose={handleCloseInvitationModal}
                title={t('memberInvitationForm.title')}
            >
                <MemberInvitationForm roles={roles} handleCloseModal={handleCloseInvitationModal} />
            </Modal>
        </div>
    );
};

export const MembersScreen = withBillingContext(withTranslationContext(withMembersContext(withOrganizationsContext(withRolesContext(MembersScreenComponent)))));
