/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FormEventHandler, FunctionComponent, useState } from 'react';
import { Backdrop } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { ButtonVariant, FormFieldType } from '../../../../types/general';
import { CmdSignatureContext, withCmdSignatureContext } from '../../../controllers/esignatures/CmdSignatureContext';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';

import { ReactComponent as ArrowLeftIcon } from '../../../../assets/images/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/arrow-right.svg';
import AutenticacaoGovLogo from '../../../../assets/images/logo-autenticacao.gov-branco.png';
import Button from '../../../elements/Button';
import CmdIcon from '../../../../assets/images/cmd-logo-short.svg';
import { ErrorResponse } from '../../../../types/errors';
import { EsignatureLoadingModal } from '../../../elements/esignatures/common/EsignatureLoadingModal';
import { EsignaturesLayout } from '../../../elements/esignatures/layouts/EsignaturesLayout';
import { FormField } from '../../../elements/FormField';
import { PhoneInput } from '../../../elements/PhoneInput';
import { RectangleStepper } from '../../../elements/RectangleStepper';
import { withOrganizationsContext } from '../../../controllers/OrganizationsContext';

type BaseProps = TranslationContext & Pick<CmdSignatureContext, 'requestSignature'|'navigateToContextPage'|'navigateToOtpPage' >

/**
 * Chave Móvel Digital credentials screen for the esignature experience with CMD
 * @param props {BaseProps}
 * @returns
 */
export const CmdSignatureCredentialsScreenBase: FunctionComponent<BaseProps> = (props: BaseProps) => {
    const {
        t,
        navigateToContextPage,
        requestSignature,
        navigateToOtpPage,
    } = props;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<ErrorResponse>();

    const [searchParams] = useSearchParams();
    const reason = searchParams.get('reason') ?? '';
    const location = searchParams.get('location') ?? '';

    const commitments = JSON.parse(decodeURIComponent(searchParams.get('commitments') ?? '[]')) ?? [] as string[];
    
    const handleFormSubmit: FormEventHandler = (e) => {
        setIsSubmitting(true);
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        requestSignature({
            phone: String(formData.get('phone')),
            pin: String(formData.get('pin')),
            commitments,
            location,
            reason,
        }).then((res) => {
            if (res[0]) {
                navigateToOtpPage(res[0].contractName, String(formData.get('phone')));
                return;
            }
            if (res[1]) {
                setError(res[1]);
            }
        }).catch(() => {
        }).finally(() => {
            setIsSubmitting(false);
        });
    };

    /**
    * JSX for the "Next" button used in the existing bottom bar of the layout.
    *
    */
    const nextBtn = (
        <Button
            id="actionNext"
            variant={ButtonVariant.Curved}
            extraClasses="primary"
            isSubmit
            testId="cmd-credentials-actionGoNext"
            disabled={isSubmitting}
        >
            <span className="btn-content">
                {t('cmdCredentialsScreen.actionGoNext')}
                <ArrowRightIcon />
            </span>
        </Button>
    
    );

    /**
    * JSX for the "Go Back" button used in the existing bottom bar of the layout.
    *
    */
    const goBackBtn = (
        <Button
            id="actionBackToViewSigningContract"
            variant={ButtonVariant.Curved}
            extraClasses="secondary"
            onClick={() => navigateToContextPage()}
            testId="cmd-credentials-actionGoBack"
            disabled={isSubmitting}
        >
            <span className="btn-content">
                <ArrowLeftIcon />
                {t('cmdCredentialsScreen.actionGoBack')}
            </span>
        </Button>
    );
    
    return (
        <>
            <form
                onSubmit={handleFormSubmit}
                autoComplete="off" // Compliance with AMA guidelines
            >
                <EsignaturesLayout primaryBtn={nextBtn} secondaryBtn={goBackBtn}>
                    <div id="cmd-signature-banner" className="cmd-signature-banner">
                        <img src={AutenticacaoGovLogo} alt="Autenticação.Gov logo" height={25} width="auto" data-testid="cmd-signature-banner" />
                    </div>
                    <div id="cmd-credentials" data-testid="cmd-credentials" className="cmd-credentials__container">
                        <div id="cmd-stepper" className="cmd-stepper">
                            <RectangleStepper currrent={1} list={[t('cmdSignatureStepper.context'), t('cmdSignatureStepper.sign')]} testId="cmd-stepper" />
                        </div>
                        <h3 className="cmd-credentials__header">{t('cmdCredentialsScreen.validationTitle')}</h3>
                        <p>{t('cmdCredentialsScreen.validationDescription')}</p>

                        <div data-testid="cmd-phone-input" className="cmd-credentials__container__cmd-phone-input">
                            <PhoneInput required name="phone" id="phone" initialValue="+351" testId="phone-input" />
                        </div>

                        <div data-testid="cmd-pin-input">
                            <FormField name="pin" maxLength={8} type={FormFieldType.Password} label={t('cmdCredentialsScreen.pinLabel')} autoComplete="off" ariaAutoComplete="none" fullWidth />
                        </div>

                        { error?.errors?.map((e) => (
                            <p key={e.message} style={{ color: 'red' }}>{e.message}</p>
                        ))}

                        <div className="cmd-credentials__container__bottom">
                            <h3 className="cmd-credentials__header">{t('cmdCredentialsScreen.cmdNotActiveTitle')}</h3>
                            <a className="cmd-credentials__link" href="https://www.autenticacao.gov.pt/cmd-pedido-chave" target="_blank" rel="noreferrer" data-testid="cmd-activation-link">{t('cmdCredentialsScreen.cmdNotActiveAction')}</a>
                            <h3 className="cmd-credentials__header">{t('cmdCredentialsScreen.hasQuestionsTitle')}</h3>
                            <a className="cmd-credentials__link" href="https://www.autenticacao.gov.pt/web/guest/politicas-e-informacao-sobre-chave-movel-digital" target="_blank" rel="noreferrer" data-testid="cmd-policy-details-link">{t('cmdCredentialsScreen.hasQuestionsDescription')}</a>
                        </div>
                    </div>
                </EsignaturesLayout>
            </form>
            {isSubmitting && (
                <Backdrop open>
                    <EsignatureLoadingModal
                        open
                        providerIconSrc={CmdIcon}
                        message={t('cmdLoadingScreen.credentialScreenText')}
                        disclaimer={t('cmdLoadingScreen.credentialScreenDisclaimer')}
                    />
                </Backdrop>
            )}
        </>
    );
};

export const CmdSignatureCredentialsScreen = withTranslationContext(withOrganizationsContext(withCmdSignatureContext(CmdSignatureCredentialsScreenBase)));
