/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactElement } from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';

import { ErrorCodeMap } from '../../types/errors';
import { FormValidatorError } from '../../utils/validations';

enum ErrorMessageType {
    Field = 'field',
    Logic = 'error'
}

interface OwnProps extends TranslationContext {
    field?: string;
    errors: FormValidatorError[] | null | undefined;
    type?: ErrorMessageType | string;
    extraClass?: string;
    showAll?: boolean;
}

const ErrorMessageBase: FunctionComponent<OwnProps> = (props: OwnProps): ReactElement => {
    const {
        t,
        field, errors, showAll, extraClass, type,
    } = props;

    function renderAPIError(error: FormValidatorError): string {
        const { errorCode } = error;
        return typeof errorCode !== 'undefined' ? t(`errors.${ErrorCodeMap[errorCode]}`) : '';
    }

    function renderFormFieldError(error: FormValidatorError): string {
        const {
            typeOfViolation,
        } = error;
        return t(`errors.${typeOfViolation}`, { ...error });
    }

    function errorSelector(error: FormValidatorError): string {
        const errorType = type ?? ErrorMessageType.Field;

        switch (errorType) {
            case ErrorMessageType.Field:
                return renderFormFieldError(error);
            case ErrorMessageType.Logic:
                return renderAPIError(error);
            default:
                return '';
        }
    }

    if (!errors || errors.length < 1) return <></>;
    if (!showAll) {
        const [error] = errors;

        return (
            <span data-field={field} className={`field-error ${extraClass ?? ''}`}>
                {errorSelector(error)}
            </span>
        );
    }

    return (
        <>
            {errors.map((error, index) => {
                const key = `error-${index}`;

                return (
                    <span key={key} data-field={field} data-testid="error-message" className={`field-error ${extraClass ?? ''}`}>
                        {errorSelector(error)}
                    </span>
                );
            })}
        </>
    );
};

export const ErrorMessage = withTranslationContext(ErrorMessageBase);

export default ErrorMessage;
