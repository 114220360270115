/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { useCornerstoneApi } from '../../api';
import { validations } from '../../constants/validations';
import {
    contractCancelUrl,
    contractPdfUrl,
    contractTypeUrl,
    contractTypesUrl,
    contractUrl,
    contractsListUrl,
    contractsUrl,
    placeholderUrl,
    placeholdersUrl,
    purchaseContractUrl,
    signatureUrl,
    signerUrl,
    signersUrl,
} from '../../services/contracts';
import { workflowContractUrl } from '../../services/workflows';
import {
    Contract,
    ContractPayload,
    ContractType,
    ContractTypePayload,
    Placeholder,
    PlaceholderPayload,
    Signer,
    SignerAsOrganizationMemberPayload,
    SignerPayload,
} from '../../types/contracts';
import { DataOrError, ErrorResponse } from '../../types/errors';
import { KeyedObject, ListResponse } from '../../types/general';
import { FormValidationError, FormValidatorError, validateForm } from '../../utils/validations';
import { ContractsContext, ContractsContextProvider } from './ContractsContext';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps;

const ContractsController: FunctionComponent<Props> = (props: Props) => {
    const { children } = props;
    const CornerstoneApiInstance = useCornerstoneApi();

    /**
     * Get Contract Types
     *
     * @remarks
     * This is a GET API request to fetch Contract Types.
     *
     * It retrieves data using a cursor, with 15 objects returned by default.
     * This can be increased to 30.
     */
    const getContractTypes = async (cursor = '', limit = ''): Promise<ListResponse<ContractType>> => {
        try {
            const params = {
                _cursor: cursor,
                _limit: limit,
            };

            const { data } = await CornerstoneApiInstance.get(contractTypesUrl(params));
            return {
                cursor: data.cursor,
                results: data.results,
            };
        } catch {
            return {
                cursor: '',
                results: [],
            };
        }
    };
    /**
     * Get Contract Types
     *
     * @remarks
     * This is a GET API request to fetch a Contract Type given an ID.
     *
     * It retrieves a contract type given an ID.
     * If it wasn't found or any exception was caught during the request, it will return null.
     */
    const getContractType = async (id: number): Promise<DataOrError<ContractType, ErrorResponse>> => {
        try {
            const { data } = await CornerstoneApiInstance.get(contractTypeUrl(id));
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Create Contract Type
     *
     * @remarks
     * This is a POST API request to create a new Contract Type.
     */
    const submitNewContractType: ContractsContext['submitNewContractType'] = async (payload) => {
        try {
            const { data } = await CornerstoneApiInstance.post(contractTypesUrl(), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Edit Contract Type
     *
     * @remarks
     * This is a PATCH API request to modify Contract Type fields, including:
     * - Name
     */
    const editContractType: ContractsContext['editContractType'] = async (contractTypeId: number, payload: Partial<ContractTypePayload>) => {
        try {
            await CornerstoneApiInstance.put(contractTypeUrl(contractTypeId), payload);
        } catch (error) {
            return error as ErrorResponse;
        }
    };

    /**
     * Delete Contract Type
     *
     * @remarks
     * This is a DELETE API request to remove a Contract Type.
     */
    const deleteContractType: ContractsContext['deleteContractType'] = async (contractTypeId: number) => {
        try {
            await CornerstoneApiInstance.delete(contractTypeUrl(contractTypeId));
        } catch (error) {
            return error as ErrorResponse;
        }
    };

    /**
     * Validate Contract Type Fields
     *
     * @remarks
     * This function validates the form fields for a Contract Type
     *  before submitting a POST request.
     */
    const validateContractTypeForm = (fields: ContractTypePayload): FormValidationError | null => {
        const errors: KeyedObject<FormValidatorError[]> | null = validateForm(fields, validations.contractTypeCreate);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    /**
     * Get contract
     *
     * @remarks
     * Fetches API for contract given an id.
     *
     * Returns a {@link Contract} object if API returns successfully.
     * If it wasn't found or any exception was caught during the request, it will return null.
     *
     * @param { number } contractId
     * @returns { Promise<Contract | null> }
     */
    const getContract = async (contractId: string): Promise<DataOrError<Contract, ErrorResponse>> => {
        try {
            const { data } = await CornerstoneApiInstance.get(contractUrl(contractId));
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Get contract PDF
     *
     * @remarks
     * Fetches API for pdf document.
     */
    const getContractPdf = async (contractId: string): Promise<DataOrError<Blob, ErrorResponse>> => {
        try {
            const { data } = await CornerstoneApiInstance.get(contractPdfUrl(contractId), { responseType: 'blob' });

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Get Contracts
     *
     * @param cursor
     */
    const getContracts: ContractsContext['getContracts'] = async (filters) => {
        try {
            const { data } = await CornerstoneApiInstance.get(contractsListUrl(filters as KeyedObject<unknown>));
            return {
                cursor: data.cursor,
                results: data.results,
            };
        } catch {
            return {
                cursor: '',
                results: [],
            };
        }
    };

    /**
     * Create Contract
     *
     * @remarks
     * This is a POST API request to create a new Contract.
     * The payload is in FormData format and includes the contract file.
     */
    const createContract: ContractsContext['createContract'] = async (payload: FormData) => {
        try {
            const { data } = await CornerstoneApiInstance.post(contractsUrl(), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Edit Contract
     *
     * @remarks
     * This is a PATCH API request to modify Contract fields, including:
     * - Name
     * - Contract Type
     * - Signature Type
     * - External ID
     */
    const editContract: ContractsContext['editContract'] = async (contractId: string, payload: ContractPayload) => {
        try {
            const { data } = await CornerstoneApiInstance.patch(contractUrl(contractId), payload);

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Swap Contract
     *
     * @remarks
     * This is a PUT API request to replace an existing Contract.
     * The payload is in FormData format and includes the new contract file.
     */
    const replaceContract: ContractsContext['replaceContract'] = async (contractId: string, payload: FormData) => {
        try {
            const { data } = await CornerstoneApiInstance.put(contractUrl(contractId), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Validate Contract Fields
     *
     * @remarks
     * This function validates the form fields for a Contract
     * before submitting a PATCH or PUT request.
     */
    const validateContractForm = (fields: Partial<ContractPayload>): FormValidationError | null => {
        const errors: KeyedObject<FormValidatorError[]> | null = validateForm(fields, validations.contractSubmit);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    /**
     * Mark Contract as Ready to Sign
     *
     * @remarks
     * This is a PUT API request to indicate that the contract is ready to sign.
     */
    const purchaseContract: ContractsContext['purchaseContract'] = async (contractId, payload) => {
        try {
            await CornerstoneApiInstance.put<undefined>(purchaseContractUrl(contractId), payload);
            return [undefined, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Create Signer
     *
     * @remarks
     * This is a POST API request to create a new Signer.
     */
    const addNewSigner: ContractsContext['addNewSigner'] = async (contractId: string, payload: SignerPayload) => {
        try {
            const { data } = await CornerstoneApiInstance.post(signersUrl(contractId), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Create Signer as an Organization Member
     *
     * @remarks
     * This is a POST API request to create a new Signer.
     */
    const addNewSignerAsOrganizationMember: ContractsContext['addNewSignerAsOrganizationMember'] = async (contractId: string, payload: SignerAsOrganizationMemberPayload) => {
        try {
            const { data } = await CornerstoneApiInstance.put(signersUrl(contractId), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const updateSigner: ContractsContext['updateSigner'] = async (contractId: number, signerId: number, payload: SignerPayload) => {
        try {
            const { data } = await CornerstoneApiInstance.put(signerUrl(contractId, signerId), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Validate Signer
     *
     * @remarks
     * This function validates the form fields for a Signer
     * before submitting a POST request.
     */
    const validateSignerForm = (fields: SignerPayload): FormValidationError | null => {
        const errors = validateForm(fields, validations.signerCreate);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    /**
     * Validate Signer as an Organization Member
     *
     * @remarks
     * This function validates the form fields for a Signer
     * before submitting a POST request.
     */
    const validateSignerAsOrganizationMemberForm = (fields: SignerAsOrganizationMemberPayload): FormValidationError | null => {
        const errors = validateForm(fields, validations.signerCreateAsOrganizationMember);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    /**
     * Delete Signer
     *
     * @remarks
     * This is a DELETE API request to remove a Signer.
     */
    const deleteSigner: ContractsContext['deleteSigner'] = async (contractId: number, signerId: number) => {
        try {
            await CornerstoneApiInstance.delete(signerUrl(contractId, signerId));
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    /**
     * Get signer
     *
     * @remarks
     * Fetches API for signer given an id.
     *
     * Returns a {@link Signer} object if API returns successfully.
     * If it wasn't found or any exception was caught during the request, it will return null.
     *
     * @param { number } contractId
     * @param { number } signerId
     * @returns { Promise<Signer | null> }
     */
    const getSigner = async (contractId: number, signerId: number): Promise<DataOrError<Signer, ErrorResponse>> => {
        try {
            const { data } = await CornerstoneApiInstance.get(signerUrl(contractId, signerId));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Create Placeholder
     *
     * @remarks
     * This is a POST API request to create a placeholder for signer signature.
     */
    const createSignaturePlaceholder: ContractsContext['createSignaturePlaceholder'] = async (contractId: string, payload: PlaceholderPayload) => {
        try {
            const { data } = await CornerstoneApiInstance.post(placeholdersUrl(contractId), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Update Placeholder
     *
     * @remarks
     * This is a PUT API request to update a placeholder for signer signature.
     */
    const updateSignaturePlaceholder: ContractsContext['updateSignaturePlaceholder'] = async (contractId: string, placeholderId: number, payload: PlaceholderPayload) => {
        try {
            const { data } = await CornerstoneApiInstance.put(placeholderUrl(contractId, placeholderId), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Delete Placeholder
     *
     * @remarks
     * This is a DELETE API request to delete a placeholder for signer signature.
     */
    const deleteSignaturePlaceholder: ContractsContext['deleteSignaturePlaceholder'] = async (contractId: string, placeholderId: number) => {
        try {
            await CornerstoneApiInstance.delete(placeholderUrl(contractId, placeholderId));
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    /**
     * Fetch contract placeholders
     *
     * @param contractId
     * @returns
     */
    const getContractPlaceholders = async (contractId: string): Promise<Placeholder[]> => {
        try {
            const { data } = await CornerstoneApiInstance.get(placeholdersUrl(contractId));
            return data;
        } catch {
            return [];
        }
    };

    /**
     * Deletes a contract
     *
     * @param contractId
     * @returns
     */
    const deleteContract: ContractsContext['deleteContract'] = async (contractId) => {
        try {
            await CornerstoneApiInstance.delete(contractUrl(contractId));
            return [undefined, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const cancelContract: ContractsContext['cancelContract'] = async (contractId, notes) => {
        try {
            await CornerstoneApiInstance.put(contractCancelUrl(contractId), { notes });
            return [undefined, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Validate Signature availability
     *
     * @remarks
     * This request validate if the signature type is available for signer country and document type
     */
    const checkSignatureAvailability = async (payload: Partial<SignerPayload>): Promise<boolean> => {
        try {
            const { data } = await CornerstoneApiInstance.post(signatureUrl(), payload);
            return data.result;
        } catch (e) {
            return false;
        }
    };

    /**
     * Create Workflow Contract
     *
     * @remarks
     * This is a POST API request to create a new Contract.
     * The payload is in FormData format and includes the contract file.
     */
    const createWorkflowContract: ContractsContext['createWorkflowContract'] = async (workflowId: string, payload: FormData) => {
        try {
            const { data } = await CornerstoneApiInstance.post(workflowContractUrl(Number(workflowId)), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    return (
        <ContractsContextProvider
            value={{
                deleteContract,
                submitNewContractType,
                getContractTypes,
                getContractType,
                editContractType,
                deleteContractType,
                validateContractTypeForm,
                getContract,
                getContractPdf,
                getContracts,
                createContract,
                editContract,
                cancelContract,
                replaceContract,
                validateContractForm,
                addNewSigner,
                addNewSignerAsOrganizationMember,
                updateSigner,
                validateSignerForm,
                validateSignerAsOrganizationMemberForm,
                deleteSigner,
                getSigner,
                createSignaturePlaceholder,
                updateSignaturePlaceholder,
                deleteSignaturePlaceholder,
                getContractPlaceholders,
                purchaseContract,
                checkSignatureAvailability,
                createWorkflowContract,
            }}
        >
            {children}
        </ContractsContextProvider>
    );
};

export default ContractsController;
