/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, useEffect,
    useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import availableCurrencies from '../../../assets/data/available-currencies.json';
import { ReactComponent as SignIcon } from '../../../assets/images/pen.svg';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import { ErrorResponse } from '../../../types/errors';
import { ButtonVariant, ProgressStep } from '../../../types/general';
import { Permissions } from '../../../types/permissions';
import {
    CurrencyInfo,
    Workflow,
    WorkflowCurrentStateSteps,
    WorkflowFrequencyType,
} from '../../../types/workflows';
import { formatNumberToLocale } from '../../../utils/number';
import { calculateCommission } from '../../../utils/workflows';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import Button from '../../elements/Button';
import { DocumentList } from '../../elements/documents/DocumentList';
import { MissingDocumentList } from '../../elements/documents/MissingDocumentList';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { ParticipantList } from '../../elements/participants/ParticipantList';
import { ProgressStepper } from '../../elements/ProgressStepper';
import { ActionButtons } from '../../elements/workflows/ActionButtons';
import { ContractList } from '../../elements/workflows/ContractList';
import { ExtraActions } from '../../elements/workflows/ExtraActions';
import { WorkflowContextProvider } from '../../elements/workflows/WorkflowContextProvider';
import { LoadingScreen } from '../LoadingScreen';
import { ShareComponent } from '../../elements/ShareComponent';
import { buildUrl } from '../../../utils/navigation';
import { AppRoute } from '../../../constants/routes';

type OwnProps = TranslationContext & WorkflowContext;

const ViewWorkflowScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getWorkflow,
        getWorkflowStates,
        getWorkflowAvailableStates,
    } = props;
    const { workflowId = '' } = useParams();
    const navigate = useNavigate();
    
    const canManage = useUserHasPermission([Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]);
    const canReviewWorkflow = useUserHasPermission([Permissions.REVIEW_ALL_ORGANIZATION_TRANSACTIONS]);
    const canReviewWorkflowDocuments = useUserHasPermission([Permissions.REVIEW_ALL_ORGANIZATION_TRANSACTIONS_DOCUMENTS]);

    const [workflow, setWorkflow] = useState<Workflow>();
    const [isLoading, setIsLoading] = useState(true);
    const [workflowSteps, setWorkflowSteps] = useState<ProgressStep[]>([]);
    const [availableSteps, setAvailableSteps] = useState<WorkflowCurrentStateSteps>();
    const [errorResponse, setErrorResponse] = useState<ErrorResponse | null>(null);
    const currentStateInHappyPath = workflowSteps.some((step) => step.value === workflow?.status);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (errorResponse) throw errorResponse;
    }, [errorResponse]);

    const getDefaultTitle = (requestedWorkflow: Workflow) => {
        const propertyType = requestedWorkflow?.property?.propertyType ? t(`propertyTypes.${requestedWorkflow.property.propertyType}`) : '';
        const typology = requestedWorkflow?.property?.typology ? t(`propertyTypologies.${requestedWorkflow.property.typology}`) : '';
        const parish = requestedWorkflow?.property?.parish ? `${t('workflows.in')} ${requestedWorkflow.property.parish.name}` : '';

        let defaultTitle = '';

        if (propertyType) defaultTitle += `${propertyType} `;
        if (typology) defaultTitle += `${typology} `;
        if (defaultTitle && parish) defaultTitle += parish;

        return defaultTitle;
    };

    const init = async () => {
        const [response, requestError] = await getWorkflow(workflowId);

        if (requestError) {
            setErrorResponse(requestError);
        }
        if (response) {
            setWorkflow({
                ...response,
                title:
                response.title
                    ? response.title
                    : getDefaultTitle(response),
            });

            const [stateSteps] = await getWorkflowAvailableStates(String(response.id));
            if (stateSteps) {
                setAvailableSteps(stateSteps);
            }
            const [states] = await getWorkflowStates(response?.transactionType);
            if (states) {
                const mappedSteps = states.map((item) => {
                    return {
                        value: item.transactionState,
                        label: t(`workflows.view.${response?.transactionType}.steps.${item.transactionState}`),
                    };
                });
                setWorkflowSteps(mappedSteps);
            }
        }
      
        setIsLoading(false);
    };

    const displayFrequency = () => {
        switch (workflow?.frequencyType) {
            case WorkflowFrequencyType.MONTHLY:
                return t('workflows.view.RENTAL.MONTHLY');
            case WorkflowFrequencyType.YEARLY:
                return t('workflows.view.RENTAL.YEARLY');
            default:
                return '';
        }
    };
    return (
        <DefaultLayout>
            {isLoading && <LoadingScreen />}
            {!isLoading && workflow && (
                <div className="workflow-screen--view" data-testid="workflow-view-screen">
                    <div className="workflow-screen--view__header">
                        <div className="workflow-screen--view__header__actions">
                            <div className="workflow-screen--view__header__actions__main">
                                {canManage && (
                                    <Button
                                        startIcon={<SignIcon />}
                                        testId="edit-btn"
                                        onClick={() => navigate(buildUrl(AppRoute.WorkflowInfo, { workflowId }))}
                                    >
                                        {t('workflows.view.edit')}
                                    </Button>
                                )}
                                <ShareComponent
                                    shareTitle={t('workflows.share.title')}
                                    text={t('workflows.share.text', { workflow })}
                                    url={window.location.href}
                                    subject={t('workflows.share.emailSubject', { workflow })}
                                    buttonType={ButtonVariant.Basic}
                                />
                            </div>
                            {canManage && (
                                <ExtraActions workflow={workflow} />
                            )}
                        </div>
                    </div>
                    <div className="workflow-screen--view__sticky-container">
                        <div className="workflow-screen--view__general-info">
                            <div>
                                {workflow.externalId && (
                                    <div className="workflow-screen--view__general-info__id" data-testid="externalid-element">
                                        {workflow.externalId}
                                    </div>
                                )}
                                <div className="workflow-screen--view__general-info__type">
                                    {t(`createWorkflowSelectType.${workflow.transactionType}`)}
                                </div>
                                <div className="workflow-screen--view__general-info__title" data-testid="workflow-title">
                                    {workflow.title}
                                </div>
                                <div className="workflow-screen--view__general-info__value">
                                    {`${formatNumberToLocale(workflow.transactionValue)} ${(availableCurrencies as { [index: string]: CurrencyInfo })[workflow.transactionCurrency].symbol}`} {displayFrequency()}
                                </div>
                                {!!workflow.comissionType && !!workflow.comissionValue && (
                                    <div className="workflow-screen--view__general-info__comission">
                                        {`${formatNumberToLocale(
                                            calculateCommission(
                                                workflow.comissionType,
                                                workflow.comissionValue,
                                                workflow.transactionValue,
                                            ),
                                        )} ${(availableCurrencies as { [index: string]: CurrencyInfo })[workflow.transactionCurrency].symbol} ${t('workflows.view.comission')}`}
                                    </div>
                                )}
                                <div className="workflow-screen--view__general-info__state">
                                    {workflowSteps && currentStateInHappyPath ? (
                                        <ProgressStepper steps={workflowSteps} currentStep={workflow.status} />
                                    ) : <div>{t(`workflows.view.${workflow.transactionType}.steps.${workflow.status}`)}</div>}
                                </div>
                                {(canManage || canReviewWorkflow || canReviewWorkflowDocuments) && availableSteps && (
                                    <ActionButtons workflowType={workflow.transactionType} availableSteps={availableSteps} />
                                )}
                                <div className="workflow-screen--view__general-info__description">
                                    {workflow.description}
                                </div>
                                <span className="separator" />
                            </div>
                        </div>
                        <div className="workflow-screen--view__section-elements">
                            <ParticipantList />
                            <ContractList />
                            <WorkflowContextProvider>
                                <MissingDocumentList />
                                <DocumentList />
                            </WorkflowContextProvider>
                            
                        </div>
                    </div>
                </div>

            )}
        </DefaultLayout>
    );
};

export const ViewWorkflowScreen = withTranslationContext(withWorkflowContext(ViewWorkflowScreenBase));
