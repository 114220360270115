/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEvent,
    FunctionComponent, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IsolateFlowLayout } from '../../elements/layouts/IsolateFlowLayout';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { ButtonVariant } from '../../../types/general';
import Button from '../../elements/Button';
import { AppRoute } from '../../../constants/routes';
import { HorizontalStepper } from '../../elements/HorizontalStepper';
import { OnboardingFlowStep } from '../../../types/billing';
import { OrganizationPayload } from '../../../types/organizations';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { FormField } from '../../elements/FormField';
import { UploadDocumentCard } from '../../elements/UploadDocumentCard';
import { ReactComponent as ImageIcon } from '../../../assets/images/image.svg';
import { FormValidationError } from '../../../utils/validations';
import { preparePageTitle } from '../../../utils/route';

type OwnProps = TranslationContext & OrganizationsContext;

export const CreateOrganizationScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        validateCreateOrganizationForm,
        submitNewOrganization,
        submitOrganizationLogo,
    } = props;
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState<File | undefined>();
    const [errorsForm, setErrorsForm] = useState<FormValidationError | null>(null);

    useEffect(() => {
        document.title = preparePageTitle(t('subscribeFlow.title'));
    }, []);
    
    const OnboardingSteps = [
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ORGANIZATION}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PLAN_REVIEW}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ADD_CREDITS}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.BILLING}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PAYMENT}`),
    ];

    /**
    * JSX for the "Next" button used in the existing bottom bar of the layout.
    *
    */
    const nextBtn = (
        <Button
            id="actionNext"
            isSubmit
            variant={ButtonVariant.Curved}
            extraClasses="primary slim"
            testId="actionNext"
        >
            <span className="btn-content">
                {t('subscribeFlow.organizationStep.createOrganizationBtn')}
            </span>
        </Button>

    );

    /**
    * JSX for the "Go Back" button used in the existing bottom bar of the layout.
    *
    */
    const goBackBtn = (
        <Button
            id="actionBack"
            variant={ButtonVariant.Curved}
            extraClasses="secondary slim"
            onClick={() => navigate(AppRoute.BillingPlans)}
            testId="actionBack"
        >
            <span className="btn-content">
                {t('general.goBack')}
            </span>
        </Button>
    );
    /**
     * Handle Image Uploaded
     *
     * @param documentFile
     */
    const handleUploadDocument = async (documentFile: File | undefined) => {
        setImage(documentFile);
    };

    const onFormSubmit = async (e: FormEvent) => {
        setIsLoading(true);
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);

        const fieldsTransformed: OrganizationPayload = {
            name: String(formData.get('name')),
            ...(formData.get('ami') && { ami: Number(formData.get('ami')) }),
        };

        const errors = validateCreateOrganizationForm(fieldsTransformed);
        setErrorsForm(errors);

        if (!errors) {
            const [organizationData, submittingError] = await submitNewOrganization(fieldsTransformed);

            if (organizationData && image) {
                // Submit logo
                const formDataImage = new FormData();
                formDataImage.append('file', image as Blob, image.name);

                const errorUploadingImage = await submitOrganizationLogo(organizationData.id, formDataImage);

                if (errorUploadingImage) {
                    toast.error(t('subscribeFlow.organizationStep.uploadingFileError'));
                }
            }

            if (submittingError) {
                toast.error(submittingError.errors[0].getMessageTranslated(t));
                return;
            }
            setIsLoading(false);
            navigate(AppRoute.BillingSelectOrganization);
        }
        setIsLoading(false);
    };
    return (
        <form
            className="form"
            onSubmit={onFormSubmit}
            data-testid="create-organization-form"
        >
            <IsolateFlowLayout
                title={t('subscribeFlow.title')}
            >
                <div className="create-organization-screen step-content" data-testid="create-organization-screen">
                    <HorizontalStepper steps={OnboardingSteps} activeStep={0} />
                    <div className="step-content__screen">
                        <h2>{t('subscribeFlow.organizationStep.createOrganizationTitle')}</h2>
                        <FormField
                            name="name"
                            label={`${t('subscribeFlow.organizationStep.organizationNameLabel')}`}
                            helperText={t('subscribeFlow.organizationStep.organizationNameHelperText')}
                            errors={errorsForm}
                            required
                            fullWidth
                        />
                        <FormField
                            name="ami"
                            label={t('subscribeFlow.organizationStep.AMILicenseLabel')}
                            errors={errorsForm}
                            fullWidth
                        />
                        <label>{t('subscribeFlow.organizationStep.logoLabel')}</label>
                        <UploadDocumentCard
                            document={{
                                id: 1,
                                title: t('subscribeFlow.organizationStep.searchFile'),
                                description: image ? `${image?.name} ${t('subscribeFlow.organizationStep.fileSelected')}` : t('subscribeFlow.organizationStep.noneFileSelected'),
                                icon: (<ImageIcon />),
                            }}
                            onUploadFileCallback={handleUploadDocument}
                            accept="image/*"
                        />
                        {isLoading && <LoadingCircles size="s" variant="primary" />}
                    </div>
                </div>
                <footer className="footer" data-testid="footer">
                    <div className="footer__btns-wrap">
                        {nextBtn}
                        {goBackBtn}
                    </div>
                </footer>
            </IsolateFlowLayout>
        </form>
    );
};

export const CreateOrganizationScreen = withTranslationContext(withOrganizationsContext(CreateOrganizationScreenBase));
