/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    Address, ContactablePerson, FiscalInfo, OrderOptions,
} from './general';
import { PublicUserDTO } from './user';
import { ResourceType } from './workflows';

export type PaymentMethodsAvailable = Array<'card'>;

export interface StripeConfig {
    publishableKey: string;
}

export interface NewPaymentMethodResponse {
    clientSecret: string;
}

export interface PaymentMethod {
    id: string;
    card: CreditCard;
    usedByDefault: boolean;
}

export interface CreditCard {
    id: string;
    name: string;
    brand: string;
    country: string;
    currency: string;
    deleted: boolean;
    expMonth: number;
    expYear: number;
    last4: string;
    status: string;
}

export type PaymentMethodsList = Array<PaymentMethod>

export enum PlanType {
    INDIVIDUAL = 'INDIVIDUAL',
    TEAM = 'TEAM'
}

export interface Basket {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    wallet: Wallet;
    subscriptionPlan: SubscriptionPlan;
    credits?: Credit;
    planValue: Money;
    creditValue: Money;
    taxValue: Money;
    totalValue: Money;
}

export interface BasketPaymentRequest {
    basketId: number;
}

export interface UpdateBasketPayload {
    subscriptionPlanId?: number;
    paymentMethodId?: string;
    creditAmount?: number;
}

export interface Money {
    value: number;
    currency: string;
}

export enum PlanSubtype {
    STARTER = 'STARTER',
    INDIVIDUAL = 'INDIVIDUAL',
    TEAM = 'TEAM',
    PROFESSIONAL = 'PROFESSIONAL',
    ENTERPRISE = 'ENTERPRISE',
}

export enum ProcessType {
    strongAuth = 'strongAuth',
    documentManagement = 'documentManagement',
    custody = 'custody',
    training = 'training',
    accountManager = 'accountManager',
    cloudIntegrations = 'cloudIntegrations'
}

export interface ManagementProcess {
    alreadyIncluded: boolean;
    processes: ProcessType[];
}

export interface PlanSignature {
    title: string;
    limit?: string;
}

export interface PlanManagementProcess {
    title: string;
    limit?: string;
}

export interface PlanPrice {
    value: number | null;
    currency: string;
}

export enum OnboardingFlowStep {
    ORGANIZATION = 'ORGANIZATION',
    PLAN_REVIEW = 'PLAN_REVIEW',
    ADD_CREDITS = 'ADD_CREDITS',
    BILLING = 'BILLING',
    PAYMENT ='PAYMENT'
}

export enum CreditAmount {
    AMOUNT_25 = '25',
    AMOUNT_50 = '50',
    AMOUNT_100 = '100',
    AMOUNT_200 = '200'
}

export interface StripePaymentIntent {
    id: string;
    amount: number;
    applicationFeeAmount: number;
    canceledAt: string;
    cancellationReason: string;
    captureMethod: string;
    clientSecret: string;
    currency: string;
    customer: string;
    description: string;
    paymentMethod: string;
    setupFutureUsage: string;
    status: StripePaymentStatus;
}

export enum StripePaymentStatus {
    STARTED = 'STARTED',
    PROCESSING = 'PROCESSING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED'
}
export interface Credit {
    amount: number;
}
export interface Wallet {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    organizationId: number | null;
    userId: string;
    stripeCustomerId: string;
    creditsBalance: Credit;
    captiveCreditsBalance: Credit;
    address?: Address | null;
    contactablePerson: ContactablePerson | null;
    fiscalInfo: FiscalInfo | null;
}

export interface WalletUpdatePayload {
    address: Address;
    contactablePerson: ContactablePerson;
    fiscalInfo: FiscalInfo;
}

export interface SubscriptionPlan {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    name: string;
    startDate: string;
    endDate: string;
    planItems: SubscriptionPlanItem[];
    annualPrice: PlanPrice | null;
    monthlyPrice: PlanPrice | null;
    subscriptionType: PlanType;
    subscriptionSku: string;
}
export interface SubscriptionPlanItem {
    id: number;
    createdDate: string;
    lastModifiedDate: string | null;
    subscriptionPlanId: number;
    featureType: PlanFeatureType;
    pricingType: PlanPricingType;
    quota: number | null;
    unitPrice: number | null;
}
export interface SubscriptionItem {
    id: number;
    subscriptionPlanId: number;
    featureType: PlanFeatureType;
    pricingType: PlanPricingType;
    quota: number;
    unitPrice: Credit;
    unitsUsed: number;
    unitsAvailable: number;
    unitsCaptive: number;
    unitsPurchased: number;
    unitsRefunded: number;
    unlockableWithCredits: boolean;
}

export enum PlanFeatureType {
    CMD_SIGNATURE = 'CMD_SIGNATURE',
    AES_SIGNATURE = 'AES_SIGNATURE',
    QES_SIGNATURE = 'QES_SIGNATURE',
    TRANSACTION = 'TRANSACTION',
    ORG_MEMBERS = 'ORG_MEMBERS',
}

export enum PlanPricingType {
    UNLIMITED_QUOTA = 'UNLIMITED_QUOTA',
    LIMITED_QUOTA = 'LIMITED_QUOTA',
    PAY_PER_USE = 'PAY_PER_USE',
}
export interface Subscription {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    walletId: number;
    subscriptionPlan: SubscriptionPlan;
    startDate: string;
    endDate: string;
    items: SubscriptionItem[];
    expired: boolean;
}

export interface OrderRequestPayload {
    walletPaymentId?: number;
    walletContextId: number;
    assetId?: number;
    resourceType: ResourceType;
}
export enum OrderPaymentStatusEnum {
    PENDING = 'PENDING',
    PAID_QUOTA = 'PAID_QUOTA',
    PAID_CREDITS = 'PAID_CREDITS'
}

export enum SettlementTypeEnum {
    QUOTA = 'QUOTA',
    CREDITS = 'CREDITS'
}
export enum PurchaseItemUsageStatusEnum {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED',
}

export interface PurchaseItem {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    walletId: number;
    orderId: number;
    settlementType: SettlementTypeEnum;
    cost: Credit;
    unitsUsed: number;
    assetId: number;
    usageStatus: PurchaseItemUsageStatusEnum;
    user?: PublicUserDTO;
    subscriptionItem: SubscriptionItem;
}

export type PurchaseItemListFilter = {
    _cursor?: string;
    _limit?: number;
    _sort?: OrderOptions;
    maxDate?: string;
    minDate?: string;
    settlementType?: SettlementTypeEnum;
    usageStatus?: PurchaseItemUsageStatusEnum;
    featureTypes?: PlanFeatureType[];
    userId?: string;
    userIds?: string[];
}

export interface Order {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    userId: string;
    subscriptionId: number;
    totalCost: Credit;
    paymentStatus: OrderPaymentStatusEnum;
    items: PurchaseItem[];
    walletCreditsMissing: Credit;
}

export interface PurchaseItemTranslated {
    quantity: number;
    featureType: PlanFeatureType;
    settlementType: SettlementTypeEnum;
    cost: Credit;
}

export interface PurchasePayload {
    walletForPaymentId?: number;
}

export interface WalletTransaction {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    previousBalance: number;
    newBalance: number;
    action: WalletAction;
    details: string;
}

export enum WalletAction {
    CREDIT = 'CREDIT',
    DEBIT = 'DEBIT',
    PURCHASE = 'PURCHASE',
    REFUND_ORDER = 'REFUND_ORDER',
    REFUND_ITEMS = 'REFUND_ITEMS',
    OPENING = 'OPENING',
}

export interface BillingEvent {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    walletId: number;
    userId: string;
    userFullName: string;
    billingAsset: BillingAsset;
    billingAction: BillingAction;
    amount: number;
    contractId: number;
    transactionId: number;
    subscriptionId: number;
}

export enum BillingAsset {
    CREDITS = 'CREDITS',
    TRANSACTION = 'TRANSACTION',
    CMD_SIGNATURE = 'CMD_SIGNATURE',
    AES_SIGNATURE = 'AES_SIGNATURE',
    QES_SIGNATURE = 'QES_SIGNATURE',
    ORG_MEMBERS = 'ORG_MEMBERS',
}

export enum BillingAction {
    DEBIT = 'DEBIT',
    PURCHASE = 'PURCHASE',
    REFUND = 'REFUND',
}

export interface BillingEventFilterParams {
    _cursor?: string;
    _limit?: string;
    _sort?: OrderOptions;
    billingAction?: BillingAction;
    billingActions?: BillingAction[];
    billingAsset?: BillingAsset;
    billingAssets?: BillingAsset[];
    maxDate?: string;
    minDate?: string;
    userId?: string;
}
