/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ClickAwayListener,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import OptionsIcon from '@mui/icons-material/MoreVert';
import { toast } from 'react-toastify';
import { ContractType, ContractTypeOptions, ContractTypePayload } from '../../../types/contracts';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import Button from '../Button';
import { FormField } from '../FormField';
import { FormValidationError } from '../../../utils/validations';
import Modal from '../Modal';
import { useAppSelector } from '../../../utils/storeHooks';
import { ButtonVariant } from '../../../types/general';

interface OwnProps extends TranslationContext, ContractsContext {
    contractType: ContractType;
    updateListOnDeleteSuccess: (id: number) => void;
    updateListOnEditSuccess: (id: number, name: string) => void;
}
enum ContractTypeFieldsName {
    Name = 'name',
}
export interface ContractTypeFields {
    [ContractTypeFieldsName.Name]: string;
}

const initialFieldsState: ContractTypeFields = {
    [ContractTypeFieldsName.Name]: '',
};

const ContractTypeListItem: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        contractType,
        updateListOnDeleteSuccess,
        updateListOnEditSuccess,
        deleteContractType,
        validateContractTypeForm,
        editContractType,
    } = props;

    const [isOpenOptions, setIsOpenOptions] = useState<boolean>(false);
    const [openModalDeleteConfirm, setOpenModalDeleteConfirm] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [contractTypeFields, setContractTypeFields] = useState<ContractTypePayload>(initialFieldsState);
    const [errorsForm, setErrorsForm] = useState<FormValidationError | null>(null);
    const organizationSelected = useAppSelector((state) => state.organization.organizationSelected);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const openOptions = () => {
        setIsOpenOptions(true);
    };

    const closeOptions = () => {
        setIsOpenOptions(false);
    };

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setIsOpenOptions(false);
        } else if (event.key === 'Escape') {
            setIsOpenOptions(false);
        }
    };

    /* DELETE */
    const handleOpenModalDeleteConfirm = () => {
        setOpenModalDeleteConfirm(true);
    };

    const handleCloseModalDeleteConfirm = () => {
        setOpenModalDeleteConfirm(false);
    };

    const handleConfirm = async () => {
        const deleteContractTypeResponse = await deleteContractType(Number(contractType.id));
        if (deleteContractTypeResponse) {
            onDeleteFailure(deleteContractTypeResponse.errors[0].getMessageTranslated(t));
        } else {
            onDeleteSuccess();
        }
        handleCloseModalDeleteConfirm();
    };

    const onDeleteSuccess = () => {
        updateListOnDeleteSuccess(contractType.id);
        toast.success(t('contractTypeConfigurator.deleteSuccess'));
    };

    const onDeleteFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    /* EDIT */
    const handleOpenModalEdit = () => {
        contractTypeFields[ContractTypeFieldsName.Name] = contractType.name;
        setOpenModalEdit(true);
    };

    const handleCloseModalEdit = () => {
        setOpenModalEdit(false);
    };

    const onEditFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!organizationSelected) return;

        const fieldsTransformed: ContractTypePayload = {
            [ContractTypeFieldsName.Name]: contractTypeFields[ContractTypeFieldsName.Name].trim(),
        };
        const errors = validateContractTypeForm({ ...fieldsTransformed });
        setErrorsForm(errors);
        if (errors) return;
        const editContractResponse = await editContractType(contractType.id, fieldsTransformed);

        if (editContractResponse) {
            return onRequestFailure(editContractResponse.errors[0].getMessageTranslated(t));
        }

        onEditSuccess();
    };

    const onFieldChange = (name: string, value: string) => {
        setContractTypeFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    const onEditSuccess = () => {
        updateListOnEditSuccess(contractType.id, contractTypeFields[ContractTypeFieldsName.Name]);
        toast.success(t('contractTypeConfigurator.editSuccess'));
        onFieldChange(ContractTypeFieldsName.Name, '');
        handleCloseModalEdit();
    };

    const onRequestFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    return (
        <li className="contracts-configurator-screen__list__item card" key={contractType.id}>
            <div className="contracts-configurator-screen__list__item__inner">
                <h4 className="contracts-configurator-screen__list__item__inner__title">
                    {contractType.name}
                </h4>
                <div className="contracts-configurator-screen__list__item__inner__options">
                    <IconButton
                        ref={anchorRef}
                        aria-label="more"
                        id="long-button"
                        aria-controls={isOpenOptions ? 'long-menu' : undefined}
                        aria-expanded={isOpenOptions ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={() => openOptions()}
                        data-testid="options-icon"
                    >
                        <OptionsIcon className="contracts-configurator-screen__list__item__inner__options__icon" color="primary" sx={{ fontSize: 26 }} />
                    </IconButton>
                    <Popper
                        sx={{ zIndex: 99 }}
                        open={isOpenOptions}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={closeOptions}>
                                        <MenuList
                                            autoFocusItem={isOpenOptions}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            {Object.values(ContractTypeOptions).map((option) => (
                                                <MenuItem onClick={option === ContractTypeOptions.DELETE ? handleOpenModalDeleteConfirm : handleOpenModalEdit} key={option} data-testid={`${option.toLowerCase()}-btn`}>{t(`contractTypeOptions.${option}`)}</MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </div>

            {/* DELETE MODAL */}
            <Modal
                open={openModalDeleteConfirm}
                title={t('contractTypeConfigurator.deleteConfirmTitle')}
                handleClose={handleCloseModalDeleteConfirm}
                handleConfirm={handleConfirm}
            >
                <p>{t('contractTypeConfigurator.deleteConfirmText')}</p>
                <p className="warning-msg">{t('contractTypeConfigurator.deleteConfirmWarning')}</p>
            </Modal>

            {/* EDIT MODAL */}
            <Modal
                open={openModalEdit}
                title={t('contractTypeConfigurator.editTitle')}
                handleClose={handleCloseModalEdit}
            >
                <form
                    className="form"
                    onSubmit={onEditFormSubmit}
                >
                    <div className="form__fields">
                        <FormField
                            name={ContractTypeFieldsName.Name}
                            value={contractTypeFields[ContractTypeFieldsName.Name]}
                            onChange={onFieldChange}
                            maxLength={250}
                            errors={errorsForm}
                            fullWidth
                        />
                    </div>
                    <div className="form__bottom">
                        <Button
                            isSubmit
                            variant={ButtonVariant.Curved}
                            extraClasses="primary"
                        >
                            {t('general.save')}
                        </Button>
                    </div>
                </form>
            </Modal>
        </li>
    );
};

export default withTranslationContext(withContractsContext(ContractTypeListItem));
