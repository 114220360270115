/**
 *
 * @Copyright 2024 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Role } from './roles';

export interface Member {
    id: number;
    userId: string;
    startDate: Date;
    endDate: Date | null;
    username: string;
    firstName: string;
    lastName: string;
    fullName: string;
    roles: Role[];
}

export interface InvitationPayload {
    organizationId: number;
    roleId: number;
    email: string;
}

export interface Invite {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    organizationId: number;
    organizationName: string;
    roleId: number;
    roleName: string;
    email: string;
    expiresAt: string;
    status: InviteStatus;
    inviteToken: string | null;
}

export enum InviteStatus {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
}

export interface InviteParams {
    _status?: string;
    _cursor?: string;
}

export enum MemberInviteFilters {
    ALL = 'ALL',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
}
