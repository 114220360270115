/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';

import { ReactNode } from 'react';
import classNames from 'classnames';
import { OptionRadioField } from '../../types/general';
import { LoadingCircles } from './LoadingCircles';
import { FormField } from './FormField';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';

type RadioAttributes = Partial<Pick<HTMLInputElement, 'required'>>

interface OwnProps<T> extends RadioAttributes, TranslationContext {
    name: string;
    extraTitleElement?: ReactNode;
    label?: string;
    options: OptionRadioField<T>[];
    value?: T;
    onChange: (name: string, value: T) => void;
    onSearch: (key: string) => void;
    disabled?: boolean;
    disabledText?: string;
    isLoading?: boolean;
    searchPlaceholder?: string;
}

const RadioGroupWithSearchBase = <T, >(props: OwnProps<T>) => {
    const {
        t,
        name,
        extraTitleElement,
        label,
        options,
        value,
        onChange,
        onSearch,
        required,
        disabled = false,
        disabledText,
        isLoading = false,
        searchPlaceholder,
    } = props;
    return (
        <div className="radio-group" data-testid={`radio-group-${name}-container`}>
            <div className="radio-group__top" data-testid={`radio-group-${name}-top`}>
                {label && (
                    <label htmlFor={name} className={`radio-group-label ${required && 'required'}`}>
                        {label}
                    </label>
                )}
                {extraTitleElement}
            </div>
            <FormField
                name="radio-group__search"
                placeholder={searchPlaceholder ?? t('general.search')}
                onChange={(_, text) => onSearch(text)}
                extraClasses="radio-group__search bg-gray-filled"
                fullWidth
            />
            {isLoading && <LoadingCircles size="xs" variant="primary" />}
            {!isLoading && (
                <>
                    <RadioGroup data-testid={`radio-group-${name}`}>
                        {options.map((option) => (
                            <div
                                key={String(option.value)}
                                className={classNames(`radio-group__item ${option.extraClasses}`)}
                            >
                                <FormControlLabel
                                    value={option.disabled || disabled ? 'disabled' : option.value}
                                    control={<Radio data-testid={`radio-${option.value}`} />}
                                    label={option.label}
                                    onChange={() => onChange(name, option.value)}
                                    disabled={option.disabled || disabled}
                                    checked={option.value === value}
                                />
                            </div>
                        ))}
                    </RadioGroup>
                    {disabled && disabledText && (<span className="disable-warning">{disabledText}</span>)}
                </>
            )}
        </div>
    );
};

export const RadioGroupWithSearch = withTranslationContext(RadioGroupWithSearchBase);
