/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, {
    useEffect,
    useState,
} from 'react';
import Keycloak from 'keycloak-js';
import { AuthenticationContext, AuthenticationContextProvider } from './AuthenticationContext';
import {
    KEYCLOAK_CLIENT_ID,
    KEYCLOAK_REALM,
    KEYCLOAK_URL,
    WEBAPP_URL,
} from '../../settings';

import { User } from '../../types/user';
import { setUser } from '../../slicers/userSlice';
import { useAppDispatch } from '../../utils/storeHooks';
import { persistor } from '../../store';

const AccessTokenMinimumValiditySeconds = 30;

const keycloak = new Keycloak({
    url: KEYCLOAK_URL,
    realm: KEYCLOAK_REALM ?? '',
    clientId: KEYCLOAK_CLIENT_ID ?? '',
});

interface Props {
    children: React.ReactNode;
}

export const AuthenticationController = ({ children }: Props) => {
    const dispatch = useAppDispatch();

    const [isReady, setIsReady] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userProfile, setUserProfile] = useState<User | null>(null);
    const [authError, setAuthError] = useState<unknown>();

    const init = async () => {
        try {
            const auth = await keycloak.init({
                checkLoginIframe: false,
                useNonce: true,
            });
            const userData = {
                contactEmail: keycloak.idTokenParsed?.email,
                firstName: keycloak.idTokenParsed?.given_name,
                lastName: keycloak.idTokenParsed?.family_name,
                id: keycloak.idTokenParsed?.sub ?? '',
                username: keycloak.idTokenParsed?.preferred_username,
                fullName: `${keycloak.idTokenParsed?.given_name ?? ''} ${keycloak.idTokenParsed?.family_name ?? ''}`,
            };
            setUserProfile(userData);
            dispatch(setUser(userData));
            
            setIsReady(true);
            setIsAuthenticated(auth);
        } catch (e: unknown) {
            if (e instanceof Error && e.message.includes('instance can only be initialized once')) {
                // NOOP error thrown when instantiating another instance of keycloak
                return;
            }
            setUserProfile(null);
            setIsAuthenticated(false);
            setIsReady(true);
            setAuthError(e);
        }
    };
    useEffect(() => {
        if (!isReady) {
            init();
        }
    }, []);

    const redirectToLogin: AuthenticationContext['redirectToLogin'] = (options) => {
        keycloak.login(options);
    };

    const redirectToSignup: AuthenticationContext['redirectToSignup'] = (options) => {
        keycloak.register(options);
    };

    const refreshAccessToken = () => {
        return keycloak.updateToken(AccessTokenMinimumValiditySeconds);
    };

    const requestLogout = () => {
        persistor.purge();
        keycloak.logout({
            redirectUri: WEBAPP_URL,
        });
    };

    keycloak.onAuthRefreshError = () => {
        redirectToLogin();
    };

    keycloak.onTokenExpired = () => {
        refreshAccessToken();
    };

    keycloak.onAuthRefreshError = () => {
        redirectToLogin();
    };

    const getAccessToken = () => {
        return keycloak.token;
    };

    useEffect(() => {
        if (isAuthenticated) {
            const refreshTimer = setInterval(() => {
                refreshAccessToken().catch(() => {
                    // token cannot be refreshed at the moment
                });
            }, AccessTokenMinimumValiditySeconds * 1000 / 2);
            return () => {
                clearInterval(refreshTimer);
            };
        }
    }, [keycloak.tokenParsed, isAuthenticated]);

    return (
        <AuthenticationContextProvider
            value={{
                redirectToSignup,
                redirectToLogin,
                requestLogout,
                getAccessToken,
                authError,
                accessToken: keycloak.token,
                user: userProfile,
                isKeycloakReady: isReady,
                isAuthenticated,
            }}
        >
            {children}
        </AuthenticationContextProvider>
    );
};
