/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import {
    ContractFieldsName,
    ContractPayload,
    ContractState,
} from '../../../../types/contracts';
import { ContractsContext, withContractsContext } from '../../../controllers/ContractsContext';
import {
    TranslationContext,
    withTranslationContext,
} from '../../../controllers/TranslationContext';

import { ReactComponent as GeneralConfigsIcon } from '../../../../assets/images/businesses.svg';
import { ButtonVariant } from '../../../../types/general';
import { FormValidationError } from '../../../../utils/validations';
import Button from '../../Button';
import { Drawer } from '../../Drawer';
import { FormField } from '../../FormField';
import { FormSelectField } from '../../FormSelectField';
import { useContractContext } from '../ContractContextProvider';
import { Tooltip } from '../../Tooltip';
import { ProgressStepper } from '../../ProgressStepper';

type OwnProps = TranslationContext & ContractsContext;

const GeneralConfigOptionComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        validateContractForm,
    } = props;

    const {
        contract,
        contractTypes,
        prepareEditContract,
    } = useContractContext();

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [errorsForm, setErrorsForm] = useState<FormValidationError | null>(null);

    const defaultValues: Partial<ContractPayload> = {
        [ContractFieldsName.Name]: contract?.name,
        [ContractFieldsName.Type]: contract?.contractType?.id,
        [ContractFieldsName.ExternalId]: contract?.externalId,
    };

    const contractSteps = [
        { value: ContractState.DRAFT, label: t('contractStates.DRAFT') },
        { value: ContractState.SIGNING, label: t('contractStates.SIGNING') },
        { value: ContractState.SIGNED, label: t('contractStates.SIGNED') },
    ];
    
    const [generalFields, setGeneralFields] = useState<Partial<ContractPayload>>(defaultValues);

    /**
     * Map Contract Types
     *
     * @remarks
     * Map Contract types for label/value needed for the select box
     */
    const mappedContractTypes = () => {
        return contractTypes.map((contractType) => ({
            value: contractType.id,
            label: contractType.name,
        }));
    };

    /**
     * onClose callback function
     *
     * @remarks
     * Close Drawer and resets defaults
     */
    const onClose = () => {
        setGeneralFields(defaultValues);
        setIsOpenDrawer(false);
    };

    /**
     * Update the changed field
     *
     * @remarks
     * Verify the field changed and update in generalFields state
     */
    const onFieldChange = (name: string, value: string | number) => {
        setGeneralFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    /**
     * Submit Edit Form
     *
     * @remarks
     * Validate and submit fields
     */
    const onFormSubmit = (e: React.FormEvent, changedFields: Partial<ContractPayload>) => {
        e.preventDefault();
        const fieldsTransformed: Partial<ContractPayload> = {
            [ContractFieldsName.Name]: generalFields.name,
            [ContractFieldsName.Type]: generalFields.contractTypeId,
            [ContractFieldsName.ExternalId]: generalFields.externalId,
            ...changedFields,
        };
        const errors = validateContractForm({ ...fieldsTransformed });
        setErrorsForm(errors);
        if (errors) return;

        prepareEditContract(fieldsTransformed, onSuccess, onFailure);
    };

    /**
     * onSuccess callback function
     *
     * @remarks
     * Update state (and other tasks if needed) after successful patch response
     */
    const onSuccess = () => {
        setIsOpenDrawer(false);
    };

    /**
     * onFailure callback function
     *
     * @remarks
     * Show toast with generic failure message
     */
    const onFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    return (
        <>
            <Button
                variant={ButtonVariant.IconBtn}
                extraClasses={classNames('first-config-btn primary',
                    { active: isOpenDrawer })
                }
                onClick={() => setIsOpenDrawer(true)}
                testId="general-configs-btn"
            >
                <Tooltip title={t('contractCreate.generalSettings')} placement="left">
                    <GeneralConfigsIcon />
                </Tooltip>
            </Button>
            <Drawer
                title={t('contractCreate.generalSettings')}
                open={isOpenDrawer}
                handleClose={onClose}
            >
                <form
                    className="form"
                    onSubmit={(e) => onFormSubmit(e, generalFields)}
                    autoComplete="off" // Prevent browsers from saving information on this form as it will not be reused on every new contract
                >
                    <div className="form__fields">
                        <div className="form__fields__state">
                            <label>{t('contractCreate.contractState')}</label>
                            {contract?.contractState && contract.contractState !== ContractState.VOIDED && (
                                <ProgressStepper steps={contractSteps} currentStep={contract?.contractState} />
                            )}
                            {contract?.contractState === ContractState.VOIDED && (<p>{t('contractStates.VOIDED')}</p>)}
                        </div>
                        <FormField
                            name={ContractFieldsName.Name}
                            value={generalFields[ContractFieldsName.Name] ?? ''}
                            label={t('contractCreate.contractName')}
                            placeholder={t('contractCreate.placeholders.name')}
                            onChange={onFieldChange}
                            maxLength={250}
                            errors={errorsForm}
                            disabled={contract?.contractState !== ContractState.DRAFT}
                            fullWidth
                        />
                        <FormSelectField
                            name={ContractFieldsName.Type}
                            options={mappedContractTypes() ?? []}
                            label={t('contractCreate.contractType')}
                            onChange={onFieldChange}
                            errors={errorsForm}
                            value={generalFields[ContractFieldsName.Type]}
                            disabled={contract?.contractState !== ContractState.DRAFT}
                            disabledText={t('contractCreate.cannotChange')}
                            fullWidth
                        />
                        <FormField
                            name={ContractFieldsName.ExternalId}
                            value={generalFields[ContractFieldsName.ExternalId] ?? ''}
                            label={t('contractCreate.contractId')}
                            onChange={onFieldChange}
                            maxLength={64}
                            errors={errorsForm}
                            placeholder={t('contractCreate.placeholders.externalId')}
                            helperText={t('contractCreate.helperTexts.externalId')}
                            disabled={contract?.contractState !== ContractState.DRAFT}
                            fullWidth
                        />
                    </div>
                    {contract?.contractState === ContractState.DRAFT && (
                        <div className="form__bottom">
                            <Button
                                isSubmit
                                variant={ButtonVariant.Curved}
                                extraClasses="primary"
                                testId="save-btn"
                            >
                                {t('general.save')}
                            </Button>
                        </div>
                    )}
                </form>
            </Drawer>
        </>
    );
};

const GeneralConfigOption = withTranslationContext(withContractsContext(GeneralConfigOptionComponent));
export { GeneralConfigOption };
