/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    ReactNode,
    useEffect,
} from 'react';
import { BillingContext, withBillingContext } from '../controllers/BillingContext';
import { OrganizationsContext, withOrganizationsContext } from '../controllers/OrganizationsContext';
import {
    setCurrentPermissions,
    setOrganizationAccesses,
    setOrganizationSelected,
    setOrganizationWallet,
    setOrganizationAccessRoles,
} from '../../slicers/organizationSlice';

import { OrganizationAccess } from '../../types/organizations';
import { useAppDispatch } from '../../utils/storeHooks';

type OwnProps = Pick<OrganizationsContext, 'organizationAccesses' | 'organizationSelected'|'getUserOrganizations'|'getOrganizationAccessRoles'> & Pick<BillingContext, 'getOrganizationWallet'> & {
    children: ReactNode;
}

/**
 * Loads the organization context globally
 *
 * @param props
 * @returns
 */
export const RequireOrganizationBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        children,
        organizationAccesses,
        organizationSelected,
        getUserOrganizations,
        getOrganizationAccessRoles,
        getOrganizationWallet,
    } = props;
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        getUserOrganizations('').then((res) => {
            dispatch(setOrganizationAccesses(res));
        });
    }, []);

    useEffect(() => {
        if (organizationAccesses?.results) {
            autoSelectOrganization(organizationAccesses.results);
        }
    }, [organizationAccesses?.results?.length]);

    useEffect(() => {
        if (organizationSelected) {
            loadRoleAndPermissions(organizationSelected);
            loadOrganizationWallet();
        }
    
        return () => {
        };
    }, [organizationSelected]);

    const autoSelectOrganization = (accesses: OrganizationAccess[]) => {
        if (accesses.length && !organizationSelected) {
            dispatch(setOrganizationSelected(accesses[0]));
        }
    };

    const loadRoleAndPermissions = async (payload: OrganizationAccess) => {
        const [currentRole] = await getOrganizationAccessRoles(payload);
        const userPermissions = currentRole?.map((r) => r.permissions)?.reduce((prev, curr) => prev.concat(curr)) ?? [];
        dispatch(setCurrentPermissions(userPermissions));
        dispatch(setOrganizationAccessRoles(currentRole ?? []));
    };

    const loadOrganizationWallet = async () => {
        const [wallet] = await getOrganizationWallet(String(organizationSelected?.organization?.id));
        dispatch(setOrganizationWallet(wallet));
    };

    return <>{children}</>;
};

export const RequireOrganization = withBillingContext(withOrganizationsContext(RequireOrganizationBase));
