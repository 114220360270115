/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, {
    FunctionComponent,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { toast } from 'react-toastify';
import CountryList from '../../../assets/data/country-dial-codes.json';
import { ReactComponent as AdvancedSignatureIcon } from '../../../assets/images/advanced-signature.svg';
import { ReactComponent as CMDLogo } from '../../../assets/images/cmd-logo-short.svg';
import { ReactComponent as QESVideoSignatureIcon } from '../../../assets/images/qes-video-siganture.svg';
import {
    ContractState,
    IdDocumentType,
    SignatureType,
    Signer,
    SignerFieldsName,
    SignerPayload,
} from '../../../types/contracts';
import { ButtonVariant } from '../../../types/general';
import { FormValidationError } from '../../../utils/validations';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../Button';
import { Drawer } from '../Drawer';
import { FormField } from '../FormField';
import { FormSelectField } from '../FormSelectField';
import { PhoneInput } from '../PhoneInput';
import { RadioSelect } from '../RadioSelect';
import { SignatureAvailabilityList } from '../SignatureAvailabilityList';
import { useContractContext } from './ContractContextProvider';
import { LoadingCircles } from '../LoadingCircles';
import { languageDropdownOptions } from '../LanguageSelector';

interface OwnProps extends TranslationContext, ContractsContext {
    isOpen: boolean;
    signerToEdit: Signer;
    onClose: () => void;
}

const DefaultPhone = '+351 ';

const EditSignerDrawerBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        isOpen,
        signerToEdit,
        t,
        languages,
        onClose,
        validateSignerForm,
        checkSignatureAvailability,
    } = props;

    const {
        contract,
        prepareToUpdateSigner,
    } = useContractContext();

    const [signatureOptions, setSignatureOptions] = useState([
        {
            value: SignatureType.QESCMD, label: t('signatureOptions.QESCMD'), icon: <CMDLogo />, disabled: false, disableMessage: t('signatureAvailability.notAvailable'), extraClasses: 'filled',
        },
        {
            value: SignatureType.QESVIDEO, label: t('signatureOptions.QESVIDEO'), icon: <QESVideoSignatureIcon />, disabled: false, disableMessage: t('signatureAvailability.notAvailable'),
        },
        {
            value: SignatureType.AES, label: t('signatureOptions.AES'), icon: <AdvancedSignatureIcon />, disabled: false, disableMessage: t('signatureAvailability.notAvailable'),
        },
    ]);

    const defaultValues: SignerPayload = {
        [SignerFieldsName.Name]: signerToEdit.name,
        [SignerFieldsName.Email]: signerToEdit.inviteEmail,
        [SignerFieldsName.PhoneNumber]: signerToEdit.phoneNumber,
        [SignerFieldsName.DocumentCountry]: signerToEdit.documentCountry,
        [SignerFieldsName.DocumentType]: signerToEdit.documentType,
        [SignerFieldsName.DocumentNumber]: signerToEdit.documentNumber,
        [SignerFieldsName.Signature]: signerToEdit.signatureType,
        [SignerFieldsName.Language]: signerToEdit.language,
    };

    const [signerFields, setSignerFields] = useState<SignerPayload>(defaultValues);
    const [errorsForm, setErrorsForm] = useState<FormValidationError | null>(null);
    const [isOpenAvailabilityDrawer, setIsOpenAvailabilityDrawer] = useState(false);
    const [isCheckingSignatureTypes, setIsCheckingSignatureTypes] = useState(true);

    const documentTypeOptions = [
        { value: IdDocumentType.IDCARD, label: t('documentTypeOptions.IDCARD') },
        { value: IdDocumentType.PASSPORT, label: t('documentTypeOptions.PASSPORT') },
        { value: IdDocumentType.DRIVERLICENSE, label: t('documentTypeOptions.DRIVERLICENSE') },
        { value: IdDocumentType.UNKNOWN, label: t('documentTypeOptions.UNKNOWN') },
    ];

    useEffect(() => {
        getSignatureAvailability();
    }, [
        signerFields.documentCountry,
        signerFields.documentType,
    ]);

    useEffect(() => {
        setSignerFields({
            [SignerFieldsName.Name]: signerToEdit.name,
            [SignerFieldsName.Email]: signerToEdit.inviteEmail,
            [SignerFieldsName.PhoneNumber]: signerToEdit.phoneNumber,
            [SignerFieldsName.DocumentCountry]: signerToEdit.documentCountry,
            [SignerFieldsName.DocumentType]: signerToEdit.documentType,
            [SignerFieldsName.DocumentNumber]: signerToEdit.documentNumber,
            [SignerFieldsName.Signature]: signerToEdit.signatureType,
            [SignerFieldsName.Language]: signerToEdit.language,
        });
    }, [signerToEdit]);

    /**
     * Map Countries
     *
     * @remarks
     * Map Countries for label/value needed for the select box
     * The usage of filter function in due the list having multiple phone dial codes, but we only need the unique country for this purpose.
     */
    const mappedCountries = useMemo(() => {
        return CountryList
            .filter((country, index, self) => index === self.findIndex((c) => c.alpha3 === country.alpha3))
            .map((country) => ({
                value: country.alpha3,
                label: country.country,
            }));
    }, [CountryList]);

    /**
     * Request signatures type availability
     *
     * @remarks
     * Request signature availability for each signature type,
     * validate if its available for user country and document type
     * and selects the first signature type radio available
     */
    const getSignatureAvailability = async () => {
        const signaturesAvailability = await Promise.all(
            signatureOptions.map((signatureOption) => {
                const payload = {
                    [SignerFieldsName.DocumentCountry]: signerFields.documentCountry,
                    [SignerFieldsName.DocumentType]: signerFields.documentType,
                    [SignerFieldsName.Signature]: signatureOption.value,
                };
                return checkSignatureAvailability(payload);
            }),
        );

        setIsCheckingSignatureTypes(false);

        const updatedSignatureAvailability = signatureOptions.map((option, index) => (
            {
                ...option,
                disabled: !signaturesAvailability[index],
            }
        ));
        setSignatureOptions(updatedSignatureAvailability);
    };

    /**
     * Update the signer field change
     *
     * @remarks
     * Verify the field changed and update in signerFields state
     */
    const onFieldsChange = (name: string, value: string | unknown) => {
        setSignerFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    /**
     * Update the signer filed on phone change
     *
     * @remarks
     * Verify the field changed and update in signerFields state
     */
    const onPhoneChange = (code: string, number: string, value: string) => {
        setSignerFields((prevFields) => ({
            ...prevFields,
            [SignerFieldsName.PhoneNumber]: value,
        }));
    };

    /**
     * Submit Add New Signer Form
     *
     * @remarks
     * Validate and submit fields
     */
    const onFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const fieldsTransformed: SignerPayload = {
            ...signerFields,
        };

        const errors = validateSignerForm({ ...signerFields });
        setErrorsForm(errors);
        if (errors?.fields) return onFailure(t('errors.fieldsError'));

        if (!errors?.fields) {
            prepareToUpdateSigner(signerToEdit.id, fieldsTransformed, onSuccess, onFailure);
        }
    };

    /**
     * onSuccess callback function
     *
     * @remarks
     * Update state (and other tasks if needed) after sucessful post response
     */
    const onSuccess = () => {
        handleClose();
    };

    /**
     * onFailure callback function
     *
     * @remarks
     * Show toast with generic failure message
     */
    const onFailure = (errorMessage: string = t('errors.general')) => {
        toast.error(errorMessage);
    };

    /**
     * Close drawer function
     *
     * Reset signer fields with default value
     * and execute onClose parent function
     *
     */
    const handleClose = () => {
        setSignerFields(defaultValues);
        onClose();
    };

    return (
        <>
            <Drawer
                title={t('contractCreate.updateSigner')}
                open={isOpen}
                handleClose={handleClose}
            >
                <form
                    className="form"
                    onSubmit={onFormSubmit}
                    data-testid="update-signer-drawer-form"
                    autoComplete="off" // Prevent browsers from saving information on this form as it contains sensitive information
                >
                    <div className="form__fields">
                        <FormField
                            name={SignerFieldsName.Name}
                            value={signerFields[SignerFieldsName.Name]}
                            onChange={onFieldsChange}
                            maxLength={250}
                            label={t('contractCreate.signer.name')}
                            placeholder={t('contractCreate.signerPlaceholders.name')}
                            errors={errorsForm}
                            fullWidth
                        />
                        <FormField
                            name={SignerFieldsName.Email}
                            value={signerFields[SignerFieldsName.Email]}
                            onChange={onFieldsChange}
                            maxLength={250}
                            label={t('contractCreate.signer.email')}
                            placeholder={t('contractCreate.signerPlaceholders.email')}
                            errors={errorsForm}
                            fullWidth
                        />
                        <div data-testid="field-phone-input">
                            <label>
                                {t('contractCreate.signer.phone')}
                            </label>
                            <PhoneInput
                                name="phoneNumber"
                                id="phoneNumber"
                                onChange={onPhoneChange}
                                errors={errorsForm}
                                testId="phone-input"
                                initialValue={signerFields[SignerFieldsName.PhoneNumber] || DefaultPhone}
                            />
                        </div>
                        <div data-testid="field-language">
                            <FormSelectField
                                name={SignerFieldsName.Language}
                                options={languageDropdownOptions({
                                    t,
                                    languages,
                                })}
                                onChange={onFieldsChange}
                                label={t('contractCreate.signer.language')}
                                errors={errorsForm}
                                value={signerFields[SignerFieldsName.Language]}
                                fullWidth
                            />
                        </div>
                        <FormSelectField
                            name={SignerFieldsName.DocumentCountry}
                            options={mappedCountries}
                            onChange={onFieldsChange}
                            label={t('contractCreate.signer.country')}
                            errors={errorsForm}
                            value={signerFields[SignerFieldsName.DocumentCountry]}
                            disabled={contract?.contractState !== ContractState.DRAFT}
                            disabledText={t('contractCreate.cannotChange')}
                            fullWidth
                        />
                        <FormSelectField
                            name={SignerFieldsName.DocumentType}
                            options={documentTypeOptions}
                            onChange={onFieldsChange}
                            label={t('contractCreate.signer.documentType')}
                            errors={errorsForm}
                            value={signerFields[SignerFieldsName.DocumentType]}
                            disabled={contract?.contractState !== ContractState.DRAFT}
                            disabledText={t('contractCreate.cannotChange')}
                            fullWidth
                        />
                        <FormField
                            name={SignerFieldsName.DocumentNumber}
                            value={signerFields[SignerFieldsName.DocumentNumber]}
                            onChange={onFieldsChange}
                            maxLength={50}
                            label={t('contractCreate.signer.documentNumber')}
                            errors={errorsForm}
                            fullWidth
                        />
                        {isCheckingSignatureTypes && (<LoadingCircles size="xs" variant="primary" />)}
                        {!isCheckingSignatureTypes && (
                            <RadioSelect
                                name={SignerFieldsName.Signature}
                                label={t('contractCreate.signer.signatureType')}
                                extraTitleElement={contract?.contractState === ContractState.DRAFT && <Button onClick={() => setIsOpenAvailabilityDrawer(true)}>{t('contractCreate.signer.seeAvailability')}</Button>}
                                options={signatureOptions}
                                value={signerFields[SignerFieldsName.Signature]}
                                onChange={onFieldsChange}
                                disabled={contract?.contractState !== ContractState.DRAFT}
                                disabledText={t('contractCreate.cannotChange')}
                            />
                        )}
                    </div>
                    <div className="form__bottom">
                        <Button
                            extraClasses="full-width"
                            isSubmit
                            variant={ButtonVariant.Curved}
                            testId="save-btn"
                        >
                            {t('contractCreate.updateSigner')}
                        </Button>
                    </div>
                </form>
            </Drawer>
            <Drawer
                title={t('signatureAvailability.title')}
                open={isOpenAvailabilityDrawer}
                handleClose={() => setIsOpenAvailabilityDrawer(false)}
                extraClasses="full-height"
            >
                <SignatureAvailabilityList />
            </Drawer>
        </>
    );
};

export const EditSignerDrawer = withTranslationContext(withContractsContext(EditSignerDrawerBase));
