/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import {
    IdDocumentType,
    SignatureType,
    Signer,
    SignerFieldsName,
    SignerPayload,
} from '../../../types/contracts';
import {
    TranslationContext,
    withTranslationContext,
} from '../../controllers/TranslationContext';

import { AddSignerDrawer } from './AddSignerDrawer';
import Modal from '../Modal';
import { ReactComponent as ProfileIcon } from '../../../assets/images/profile.svg';
import Switch from '../Switch';
import { User } from '../../../types/user';
import { useContractContext } from './ContractContextProvider';

interface OwnProps extends TranslationContext, ContractsContext {
    user: User;
    signer?: Signer;
}

const UserAsSignerListItemComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        language,
        user,
        signer,
    } = props;
    const [isOpenAddSigner, setIsOpenAddSigner] = useState(false);
    const [openModalDeleteConfirm, setOpenModalDeleteConfirm] = useState(false);
    const { contract, prepareToRemoveSigner } = useContractContext();

    const defaultValues: SignerPayload = {
        [SignerFieldsName.Name]: user.fullName,
        [SignerFieldsName.Email]: user.contactEmail,
        [SignerFieldsName.PhoneNumber]: '',
        [SignerFieldsName.DocumentCountry]: 'PRT',
        [SignerFieldsName.DocumentType]: IdDocumentType.IDCARD,
        [SignerFieldsName.DocumentNumber]: '',
        [SignerFieldsName.Signature]: SignatureType.QESCMD,
        [SignerFieldsName.Language]: language,
    };

    /**
     * Toggle User's Signer Role
     *
     * @remarks
     * Function triggered on switch click.
     * Prepares user object for new signer creation (POST request)
     * or opens delete confirmation modal to remove the user.
     */
    const toggleCurrentUserAsSigner = () => {
        if (!contract || !signer) {
            // add current user
            setIsOpenAddSigner(true);
        } else {
            // delete
            setOpenModalDeleteConfirm(true);
        }
    };

    /**
     * Delete Signer
     *
     * @remarks
     * Initiates a DELETE request to remove this user from signers
     */
    const handleDeleteConfirm = async () => {
        if (!signer) return toast.error(t('errors.general'));
        await prepareToRemoveSigner(signer.id, onDeleteSuccess, onDeleteFailure);
    };

    /**
     * onSuccess calback function
     *
     * @remarks
     * Update state (and other tasks if needed) after sucessfull delete response
     */
    const onDeleteSuccess = () => {
        setOpenModalDeleteConfirm(false);
        toast.success(t('contractCreate.deleteSuccess'));
    };

    /**
     * onFailure calback function
     *
     * @remarks
     * Update state and show toast with generic failure message
     */
    const onDeleteFailure = (errorMessage: string) => {
        setOpenModalDeleteConfirm(false);
        toast.error(errorMessage);
    };

    return (
        <>
            <div className="form__fields__signer-line this-user" key={user.id}>
                <div className="form__fields__signer-line__info">
                    <div className="form__fields__signer-line__info__icon-wrap">
                        <ProfileIcon />
                    </div>
                    <p>{`${user.fullName}`}</p>
                </div>
                <div className="form__fields__signer-line__actions">
                    <Switch
                        value={!!signer}
                        name={user.contactEmail}
                        onChange={toggleCurrentUserAsSigner}
                    />
                </div>
            </div>

            {/* DELETE MODAL */}
            <Modal
                open={openModalDeleteConfirm}
                title={t('contractCreate.deleteConfirmTitle')}
                handleClose={() => setOpenModalDeleteConfirm(false)}
                handleConfirm={handleDeleteConfirm}
            >
                <p>{t('contractCreate.deleteConfirmText')}</p>
            </Modal>
            <AddSignerDrawer
                isOpen={isOpenAddSigner}
                defaultSignerFields={defaultValues}
                onClose={(() => setIsOpenAddSigner(false))}
                signers={[]}
                isKnownUser
            />
        </>
    );
};
const UserAsSignerListItem = withTranslationContext(withContractsContext(UserAsSignerListItemComponent));
export { UserAsSignerListItem };
