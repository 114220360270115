/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    useMemo,
    useState,
} from 'react';
import CountryList from '../../../assets/data/country-dial-codes.json';
import {
    AddSignerType,
    IdDocumentType,
    SignatureOption,
    SignerFieldsName,
    SignerPayload,
} from '../../../types/contracts';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../Button';
import { Drawer } from '../Drawer';
import { FormField } from '../FormField';
import { FormSelectField } from '../FormSelectField';
import { languageDropdownOptions } from '../LanguageSelector';
import { LoadingCircles } from '../LoadingCircles';
import { PhoneInput } from '../PhoneInput';
import { RadioSelect } from '../RadioSelect';
import { SignatureAvailabilityList } from '../SignatureAvailabilityList';
import { FormValidationError } from '../../../utils/validations';
import { ReactComponent as BackIcon } from '../../../assets/images/chevron-left.svg';

interface OwnProps extends TranslationContext {
    signerType: AddSignerType;
    signerFields: SignerPayload;
    onFieldsChange(name: string, value: string | unknown): void;
    onPhoneChange(code: string, number: string, value: string): void;
    errorsForm: FormValidationError | null;
    signatureOptions: SignatureOption[];
    isCheckingSignatureTypes: boolean;
    onBack(): void;
}

const DefaultPhone = '+351 ';

const SignerFormBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        languages,
        signerType,
        signerFields,
        onFieldsChange,
        onPhoneChange,
        onBack,
        errorsForm,
        signatureOptions,
        isCheckingSignatureTypes,
    } = props;

    const [isOpenAvailabilityDrawer, setIsOpenAvailabilityDrawer] = useState(false);

    const documentTypeOptions = [
        { value: IdDocumentType.IDCARD, label: t('documentTypeOptions.IDCARD') },
        { value: IdDocumentType.PASSPORT, label: t('documentTypeOptions.PASSPORT') },
        { value: IdDocumentType.DRIVERLICENSE, label: t('documentTypeOptions.DRIVERLICENSE') },
        { value: IdDocumentType.UNKNOWN, label: t('documentTypeOptions.UNKNOWN') },
    ];
    /**
     * Map Countries
     *
     * @remarks
     * Map Countries for label/value needed for the select box
     * The usage of filter function in due the list having multiple phone dial codes, but we only need the unique country for this purpose.
     */
    const mappedCountries = useMemo(() => {
        return CountryList
            .filter((country, index, self) => index === self.findIndex((c) => c.alpha3 === country.alpha3))
            .map((country) => ({
                value: country.alpha3,
                label: country.country,
            }));
    }, [CountryList]);

    return (
        <>
            <div className="signer-form form__fields">
                {signerType === AddSignerType.EXTERNAL && (
                    <>
                        <Button
                            extraClasses="back-button"
                            startIcon={<BackIcon />}
                            onClick={onBack}
                        >
                            {t('contractCreate.searchOtherSigner')}
                        </Button>

                        <FormField
                            name={SignerFieldsName.Name}
                            value={signerFields[SignerFieldsName.Name]}
                            onChange={onFieldsChange}
                            maxLength={250}
                            label={t('contractCreate.signer.name')}
                            placeholder={t('contractCreate.signerPlaceholders.name')}
                            errors={errorsForm}
                            fullWidth
                        />
                        <FormField
                            name={SignerFieldsName.Email}
                            value={signerFields[SignerFieldsName.Email]}
                            onChange={onFieldsChange}
                            maxLength={250}
                            label={t('contractCreate.signer.email')}
                            placeholder={t('contractCreate.signerPlaceholders.email')}
                            errors={errorsForm}
                            fullWidth
                        />
                    </>
                )}
                <div data-testid="field-phone-input">
                    <label>
                        {t('contractCreate.signer.phone')}
                    </label>
                    <PhoneInput
                        name="phoneNumber"
                        id="phoneNumber"
                        onChange={onPhoneChange}
                        errors={errorsForm}
                        testId="phone-input"
                        initialValue={DefaultPhone}
                    />
                </div>
                <div data-testid="field-language">
                    <FormSelectField
                        name={SignerFieldsName.Language}
                        options={languageDropdownOptions({
                            t,
                            languages,
                        })}
                        onChange={onFieldsChange}
                        label={t('contractCreate.signer.language')}
                        errors={errorsForm}
                        value={signerFields[SignerFieldsName.Language]}
                        fullWidth
                    />
                </div>
                <FormSelectField
                    name={SignerFieldsName.DocumentCountry}
                    options={mappedCountries}
                    onChange={onFieldsChange}
                    label={t('contractCreate.signer.country')}
                    errors={errorsForm}
                    value={signerFields[SignerFieldsName.DocumentCountry]}
                    fullWidth
                />
                <FormSelectField
                    name={SignerFieldsName.DocumentType}
                    options={documentTypeOptions}
                    onChange={onFieldsChange}
                    label={t('contractCreate.signer.documentType')}
                    errors={errorsForm}
                    value={signerFields[SignerFieldsName.DocumentType]}
                    fullWidth
                />
                <FormField
                    name={SignerFieldsName.DocumentNumber}
                    value={signerFields[SignerFieldsName.DocumentNumber]}
                    onChange={onFieldsChange}
                    maxLength={50}
                    label={t('contractCreate.signer.documentNumber')}
                    errors={errorsForm}
                    fullWidth
                />
                {/* Signature Types */}
                {isCheckingSignatureTypes && (<LoadingCircles size="xs" variant="primary" />)}
                {!isCheckingSignatureTypes && (
                    <div data-testid="field-signature-select">
                        <RadioSelect
                            name={SignerFieldsName.Signature}
                            label={t('contractCreate.signer.signatureType')}
                            extraTitleElement={<Button onClick={() => setIsOpenAvailabilityDrawer(true)}>{t('contractCreate.signer.seeAvailability')}</Button>}
                            options={signatureOptions}
                            value={signerFields[SignerFieldsName.Signature]}
                            onChange={onFieldsChange}
                        />
                    </div>
                )}
            </div>

            <Drawer
                title={t('signatureAvailability.title')}
                open={isOpenAvailabilityDrawer}
                handleClose={() => setIsOpenAvailabilityDrawer(false)}
                extraClasses="full-height"
            >
                <SignatureAvailabilityList />
            </Drawer>
        </>
    );
};

export const SignerForm = withTranslationContext(SignerFormBase);
