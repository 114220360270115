/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { TabContext, TabList } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, {
    FunctionComponent,
    SyntheticEvent,
    useEffect,
    useState,
} from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as AdvancedSignatureIcon } from '../../../assets/images/advanced-signature.svg';
import { ReactComponent as CMDLogo } from '../../../assets/images/cmd-logo-short.svg';
import { ReactComponent as QESVideoSignatureIcon } from '../../../assets/images/qes-video-siganture.svg';
import { ReactComponent as UserProfileIcon } from '../../../assets/images/user-profile.svg';
import {
    AddSignerType,
    IdDocumentType,
    SignatureOption,
    SignatureType,
    Signer,
    SignerAsOrganizationMemberPayload,
    SignerFieldsName,
    SignerPayload,
} from '../../../types/contracts';
import { ButtonVariant, OptionRadioField } from '../../../types/general';
import { FormValidationError, validateForm } from '../../../utils/validations';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../Button';
import { Drawer } from '../Drawer';
import { FormField } from '../FormField';
import { LoadingCircles } from '../LoadingCircles';
import { RadioGroupWithSearch } from '../RadioGroupWithSearch';
import { useContractContext } from './ContractContextProvider';
import { SignerForm } from './SignerForm';
import { validations } from '../../../constants/validations';
import { UserContext, withUserContext } from '../../controllers/UserContext';

import { UserCard } from '../UserCard';
import { User } from '../../../types/user';
import { Alert } from '../Alert';

interface OwnProps extends TranslationContext, ContractsContext, OrganizationsContext, UserContext {
    isOpen: boolean;
    defaultSignerFields?: SignerPayload;
    onClose: () => void;
    signers: Signer[];
    isKnownUser?: boolean;
}

const AddSignerDrawerBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        isOpen,
        defaultSignerFields,
        onClose,
        t,
        language,
        checkSignatureAvailability,
        validateSignerForm,
        validateSignerAsOrganizationMemberForm,
        organizationSelected,
        getOrganizationMembers,
        signers,
        getUserByEmail,
        isKnownUser,
    } = props;

    const {
        prepareToAddSigner,
        prepareToAddSignerAsOrganizationMember,
    } = useContractContext();

    const defaultValues: SignerPayload = {
        [SignerFieldsName.Name]: '',
        [SignerFieldsName.Email]: '',
        [SignerFieldsName.PhoneNumber]: '',
        [SignerFieldsName.DocumentCountry]: 'PRT',
        [SignerFieldsName.DocumentType]: IdDocumentType.IDCARD,
        [SignerFieldsName.DocumentNumber]: '',
        [SignerFieldsName.Signature]: SignatureType.QESCMD,
        [SignerFieldsName.Language]: language,
    };

    const [signatureOptions, setSignatureOptions] = useState<SignatureOption[]>([
        {
            value: SignatureType.QESCMD, label: t('signatureOptions.QESCMD'), icon: <CMDLogo />, disabled: false, disableMessage: t('signatureAvailability.notAvailable'), extraClasses: 'filled',
        },
        {
            value: SignatureType.QESVIDEO, label: t('signatureOptions.QESVIDEO'), icon: <QESVideoSignatureIcon />, disabled: false, disableMessage: t('signatureAvailability.notAvailable'),
        },
        {
            value: SignatureType.AES, label: t('signatureOptions.AES'), icon: <AdvancedSignatureIcon />, disabled: false, disableMessage: t('signatureAvailability.notAvailable'),
        },
    ]);
    const [currentType, setCurrentType] = useState<AddSignerType>(AddSignerType.EXTERNAL);
    const [externalSignerStep, setExternalSignerStep] = useState<0 | 1>(isKnownUser ? 1 : 0);
    const [internalSignerStep, setInternalSignerStep] = useState<0 | 1>(0);
    const [isLoadingMembers, setIsLoadingMembers] = useState(true);
    const [memberOptions, setMemberOptions] = useState<OptionRadioField<string>[]>([]);
    const [selectedOrganizationMemberId, setSelectedOrganizationMemberId] = useState<string>('');
    const [signerFields, setSignerFields] = useState<SignerPayload>(defaultSignerFields ?? defaultValues);
    const [errorsForm, setErrorsForm] = useState<FormValidationError | null>(null);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [externalUser, setExternalUser] = useState<User | null>(null);
    const [userStatus, setUserStatus] = useState<number | null>(null);

    useEffect(() => {
        prepareOrganizationMembers();
    }, [signers]);

    useEffect(() => {
        getSignatureAvailability();
    }, [signerFields.documentCountry, signerFields.documentType]);

    /**
     * Get organization members
     *
     */
    const prepareOrganizationMembers = async (search: string = '') => {
        if (!organizationSelected?.organization?.id) return;

        const [membersData] = await getOrganizationMembers(organizationSelected.organization.id, search);

        if (membersData) {
            const membersTransformedOptions = membersData.map((option) => {
                const isDisabled = signers?.some((p) => p.signerUserId === option.userId);
                const disabledText = isDisabled ? ` ${t('contractCreate.signerAlreadyInContract')}` : '';
                
                return {
                    id: option.userId,
                    value: option.userId,
                    label: `${option.firstName} ${option.lastName}${disabledText}`,
                    disabled: isDisabled,
                    
                };
            });
            setMemberOptions(membersTransformedOptions);
        }
        setIsLoadingMembers(false);
    };

    /**
     * Request signatures type availability
     *
     * @remarks
     * Request signature availability for each signature type,
     * validate if its available for user country and document type
     * and selects the first signature type radio available
     */
    const getSignatureAvailability = async () => {
        const signaturesAvailability = await Promise.all(
            signatureOptions.map((signatureOption) => {
                const payload = {
                    [SignerFieldsName.DocumentCountry]: signerFields.documentCountry,
                    [SignerFieldsName.DocumentType]: signerFields.documentType,
                    [SignerFieldsName.Signature]: signatureOption.value,
                };
                return checkSignatureAvailability(payload);
            }),
        );

        const updatedSignatureAvailability = signatureOptions.map((option, index) => (
            {
                ...option,
                disabled: !signaturesAvailability[index],
            }
        ));
        setSignatureOptions(updatedSignatureAvailability);

        const firstSignatureTypeAvailable = updatedSignatureAvailability.find((option) => option.disabled === false);
        if (firstSignatureTypeAvailable) onFieldsChange(SignerFieldsName.Signature, firstSignatureTypeAvailable.value);
    };

    /**
     * Update the signer field change
     *
     * @remarks
     * Verify the field changed and update in signerFields state
     */
    const onFieldsChange = (name: string, value: string | unknown) => {
        setSignerFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    /**
     * Update the signer filed on phone change
     *
     * @remarks
     * Verify the field changed and update in signerFields state
     */
    const onPhoneChange = (code: string, number: string, value: string) => {
        setSignerFields((prevFields) => ({
            ...prevFields,
            [SignerFieldsName.PhoneNumber]: value,
        }));
    };

    /**
     * Submit Add New Signer Form
     *
     * @remarks
     * Validate and submit fields
     */
    const onFormSubmitForNewSigner = (e: React.FormEvent) => {
        e.preventDefault();
        const fieldsTransformed: SignerPayload = {
            ...signerFields,
        };

        const errors = validateSignerForm({ ...signerFields });
      
        setErrorsForm(errors);
        if (errors?.fields) return onFailure(t('errors.fieldsError'));

        if (!errors?.fields) {
            prepareToAddSigner(fieldsTransformed, onSuccess, onFailure);
        }
    };

    /**
     * Submit Add New Signer as an Organization Member Form
     *
     * @remarks
     * Validate and submit fields
     */
    const onFormSubmitForNewSignerAsOrganizationMember = (e: React.FormEvent) => {
        e.preventDefault();
        const fieldsTransformed: SignerAsOrganizationMemberPayload = {
            userId: selectedOrganizationMemberId,
            phoneNumber: signerFields[SignerFieldsName.PhoneNumber],
            documentCountry: signerFields[SignerFieldsName.DocumentCountry],
            documentType: signerFields[SignerFieldsName.DocumentType],
            documentNumber: signerFields[SignerFieldsName.DocumentNumber],
            signatureType: signerFields[SignerFieldsName.Signature],
            language: signerFields[SignerFieldsName.Language],
        };

        const errors = validateSignerAsOrganizationMemberForm({ ...fieldsTransformed });
       
        setErrorsForm(errors);
        if (errors?.fields) return onFailure(t('errors.fieldsError'));

        if (!errors?.fields) {
            prepareToAddSignerAsOrganizationMember(fieldsTransformed, onSuccess, onFailure);
        }
    };

    /**
     * onSuccess callback function
     *
     * @remarks
     * Update state (and other tasks if needed) after successful post response
     */
    const onSuccess = () => {
        handleClose();
    };

    /**
     * onFailure callback function
     *
     * @remarks
     * Show toast with generic failure message
     */
    const onFailure = (errorMessage: string = t('errors.general')) => {
        toast.error(errorMessage);
    };

    /**
     * Close drawer function
     *
     * Reset signer fields with default value
     * and execute onClose parent function
     *
     */
    const handleClose = () => {
        setSignerFields(defaultValues);
        onClose();
    };

    const handleTabChange = (_: SyntheticEvent, newValue: AddSignerType) => {
        setCurrentType(newValue);
        setErrorsForm(null);
    };

    const handleEmailValidation = (name: string, value: string | unknown) => {
        const trimmedValue = value?.toString().trim();
        onFieldsChange(name, trimmedValue);
        
        const errors = validateForm({ [name]: trimmedValue }, validations.signerEmail);

        if (trimmedValue && !errors) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
       
        setExternalUser(null);
        setUserStatus(null);
        setExternalSignerStep(0);
    };

    /**
     * Get External User by email
     *
    */
    const handleSearch = async () => {
        setIsSearching(true);
        const [userResponse, error] = await getUserByEmail(signerFields.email?.toString().trim());
        setIsSearching(false);

        if (userResponse?.data) {
            setExternalUser(userResponse?.data);
            setUserStatus(userResponse?.status);
            onFieldsChange('name', userResponse?.data.fullName);
        } else if (userResponse?.status === 204) {
            setUserStatus(userResponse?.status);
            setExternalUser(null);
        }
        if (error) {
            toast.error(t('errors.findUserByEmail'));
        }
    };

    const handleExternalNextStep = () => {
        setExternalSignerStep(1);
    };

    const handleExternalPreviousStep = () => {
        setExternalSignerStep(0);
        setSignerFields(defaultValues);
        setExternalUser(null);
        setUserStatus(null);
        setInternalSignerStep(0);
    };

    const handleRadioButtonGroup = (value: string) => {
        setSelectedOrganizationMemberId(value);
        setInternalSignerStep(1);
    };
    return (
        <Drawer
            title={t('contractCreate.newSigner')}
            open={isOpen}
            handleClose={handleClose}
        >
            <TabContext value={currentType}>
                <Box>
                    <TabList onChange={handleTabChange}>
                        <Tab label={t('contractCreate.externalSigner')} value={AddSignerType.EXTERNAL} />
                        <Tab label={t('contractCreate.organizationMember')} value={AddSignerType.CURRENT_ORGANIZATION} disabled={isLoadingMembers} />
                    </TabList>
                </Box>
            </TabContext>
            <form
                className="form"
                onSubmit={currentType === AddSignerType.CURRENT_ORGANIZATION ? onFormSubmitForNewSignerAsOrganizationMember : onFormSubmitForNewSigner}
                data-testid="add-signer-drawer-form"
                autoComplete="off"
            >
                {currentType === AddSignerType.EXTERNAL && (
                    <>
                        { externalSignerStep === 0
                            && (
                                <div className="form__fields">
                                    <FormField
                                        name={SignerFieldsName.Email}
                                        value={signerFields[SignerFieldsName.Email]}
                                        onChange={handleEmailValidation}
                                        maxLength={250}
                                        label={t('contractCreate.signer.email')}
                                        placeholder={t('contractCreate.signerPlaceholders.email')}
                                        errors={errorsForm}
                                        fullWidth
                                    />
                                    { userStatus === 204
                                        && (
                                            <Alert
                                                variant="info"
                                                icon={<UserProfileIcon />}
                                                message={t('contractCreate.signerNotFound')}
                                            />
                                        )
                                    }
                                    { externalUser && userStatus === 200
                                        && (
                                            <UserCard
                                                user={externalUser}
                                                setSignerStep={handleExternalNextStep}
                                            />
                                        )
                                    }
                                </div>
                            )
                            }
                        { isSearching
                                && <LoadingCircles size="xs" variant="secondary" />
                            }
                            
                        {externalSignerStep === 1 && (
                            <SignerForm
                                signatureOptions={signatureOptions}
                                signerFields={signerFields}
                                errorsForm={errorsForm}
                                isCheckingSignatureTypes={false}
                                signerType={currentType}
                                onFieldsChange={onFieldsChange}
                                onPhoneChange={onPhoneChange}
                                onBack={handleExternalPreviousStep}
                            />
                        )}
            
                    </>
                )}

                {currentType === AddSignerType.CURRENT_ORGANIZATION && (
                    <>
                        <div className="form__fields">
                            <RadioGroupWithSearch
                                options={memberOptions}
                                name="organization-members"
                                onChange={(_, value) => handleRadioButtonGroup(value as string)}
                                value={selectedOrganizationMemberId}
                                onSearch={(search) => prepareOrganizationMembers(search)}
                                isLoading={isLoadingMembers ?? isSearching}
                            />
                        </div>
                        { selectedOrganizationMemberId
                            && (
                                <SignerForm
                                    signatureOptions={signatureOptions}
                                    signerFields={signerFields}
                                    errorsForm={errorsForm}
                                    isCheckingSignatureTypes={false}
                                    signerType={currentType}
                                    onFieldsChange={onFieldsChange}
                                    onPhoneChange={onPhoneChange}
                                    onBack={handleExternalPreviousStep}
                                />
                            )
                            }
                    </>
                )}
                <div className="form__bottom">
                    {currentType === AddSignerType.EXTERNAL && externalSignerStep === 0 && (
                        <Button
                            extraClasses="full-width"
                            isSubmit
                            variant={ButtonVariant.Curved}
                            testId="search-button"
                            disabled={!userStatus && (!isValidEmail || isSearching || !signerFields.email)}
                            onClick={userStatus ? () => setExternalSignerStep(1) : handleSearch}
                        >
                            {userStatus
                                ? t('contractCreate.next')
                                : t('contractCreate.signer.search')}
                        </Button>
                    )}
                        
                    {((currentType === AddSignerType.EXTERNAL && externalSignerStep === 1) || (currentType === AddSignerType.CURRENT_ORGANIZATION && internalSignerStep === 1)) && (
                        <Button
                            extraClasses="full-width"
                            isSubmit
                            variant={ButtonVariant.Curved}
                            testId="save-btn"
                        >
                            {t('contractCreate.placeSignature')}
                        </Button>
                    )}
                </div>
            </form>
        </Drawer>
    );
};

export const AddSignerDrawer = withUserContext(withOrganizationsContext(withTranslationContext(withContractsContext(AddSignerDrawerBase))));
